const base = {
	searchBarMain: {
		searchButton: {
			label: 'Suchen',
		},
		travelType: {
			hotelOnly: 'Nur Hotel',
			package: 'Pauschalreise',
		},
	},
	close: 'Schließen',
	buttons: {
		booking: 'Zur Buchung',
		verifyOffer: 'Angebot prüfen',
		flightDetails: 'Flugdetails',
	},
	alternativeFlights: {
		currentOffer: 'Originalflug',
	},
	filtersSection: {
		dealsOnly: {
			title: 'Restplätze',
			label: 'Angebote mit Preissenkung',
		},
	},
	flightDetail: {
		header: 'Voraussichtliche Flugdaten',
		legalText:
			'Der Reiseveranstalter behält sich Änderungen der geplanten Flugzeiten im Rahmen der gesetzlichen und vertraglichen Möglichkeiten vor.',
	},
	button: {
		ok: 'Übernehmen',
		cancel: 'Verwerfen',
	},
	calendar: {
		confirmDialog: {
			title: 'Möchten Sie die Auswahl verwerfen?',
		},
		daysOfWeek: {
			monday: 'Montag',
			tuesday: 'Dienstag',
			wednesday: 'Mittwoch',
			thursday: 'Donnerstag',
			friday: 'Freitag',
			saturday: 'Samstag',
			sunday: 'Sonntag',
		},
	},
	pricing: {
		surcharge: 'Aufpreis',
		discount: 'Abschlag',
		total: 'Gesamtpreis',
	},
} as const;

export type BaseStructure = typeof base;

export default base;
