import { EventBus } from '@global-js/event-bus';
import { MAX_FAVORITE_HOTELS_LIMIT } from '@global-js/constants';
import Cookies from 'js-cookie';
import type { FavoritedHotel, PersistedFavoriteHotel } from '@/interfaces/api/v1-hotel-list';
import * as hotelListService from '@/components/common/services/hotelListService';
import type BookmarkAnalytics from './analytics/BookmarkAnalytics';
import { getBaseURLParts } from '../global/helper';
// overrides the default encoding implementation

// CODE TO BE REMOVED
const TTCookies = Cookies.withConverter({
	read(value) {
		return decodeURI(value);
	},
	write(value) {
		if (typeof value === 'string') {
			return encodeURI(value);
		}
		return encodeURI('');
	},
});

const getTTFavoriteCookieName = () => {
	const baseURLParts = getBaseURLParts();
	const TLDshort = baseURLParts[baseURLParts.length - 1].toUpperCase();
	const client = baseURLParts[baseURLParts.length - 2].toLowerCase();
	let ttCookieName = 'favorites_RESTPLATZBOERSE-' + TLDshort;

	if (TLDshort === 'COM') {
		ttCookieName = 'favorites_RESTPLATZBOERSE-COM';
	}

	if (TLDshort === 'DE') {
		ttCookieName = 'favorites_TOURISTIKBOERSE-DE';
	}

	if (client === 'kuoni') {
		ttCookieName = 'favorites_KUONI';
	}

	if (client === 'lastminute') {
		ttCookieName = 'favorites_LASTMINUTE-AT';
	}

	return ttCookieName;
};

const getTTFavoriteCookieDomain = () => {
	const baseURLParts = getBaseURLParts();
	return baseURLParts[baseURLParts.length - 2] + '.' + baseURLParts[baseURLParts.length - 1];
};

const getLegacyPersistedHotels = () => {
	const ttCookieName = getTTFavoriteCookieName();
	const cookieValue = TTCookies.get(ttCookieName);
	let ttCookie: FavoritedHotel[] | null = [];
	if (typeof cookieValue === 'string') {
		try {
			ttCookie = JSON.parse(cookieValue).hotels || [];
		} catch {
			return null;
		}
	}
	return ttCookie;
};

const deleteCookie = () => {
	const ttCookieName = getTTFavoriteCookieName();
	const cookieProps = {
		expires: -180,
		domain: getTTFavoriteCookieDomain(),
		secure: true,
		sameSite: 'strict' as const,
	};

	TTCookies.set(ttCookieName, 'void(0)', cookieProps);
};

const handleMigration = () => {
	const hotelsCookie = getLegacyPersistedHotels();
	if (!hotelsCookie) return;
	if (hotelsCookie.length) {
		updateStorage(hotelsCookie);
	}
	deleteCookie();
};

// END OF CODE TO BE REMOVED

const getTTFavoritePersistenceKey = () => {
	return 'hotelsWishlist';
};

export function loadLocalStorageFavoriteHotel(): PersistedFavoriteHotel {
	const hotelsWishlist = window.localStorage.getItem(getTTFavoritePersistenceKey());

	if (!hotelsWishlist) {
		return { updatedAt: 0, createdAt: 0, hotels: [] };
	}
	const state = JSON.parse(hotelsWishlist) as PersistedFavoriteHotel;

	return state;
}

const getWishlistHotels = async () => {
	handleMigration(); // CODE TO BE REMOVED
	return loadLocalStorageFavoriteHotel();
};

const updateStorage = (hotels: FavoritedHotel[]) => {
	let workingHotels = [...hotels];

	// First ensure we don't exceed the maximum number of hotels
	if (workingHotels.length > MAX_FAVORITE_HOTELS_LIMIT) {
		workingHotels = workingHotels.slice(0, MAX_FAVORITE_HOTELS_LIMIT);
	}

	saveFavoritesHotel(workingHotels);

	// Emit events in next tick to ensure DOM updates
	setTimeout(() => {
		EventBus.$emit('bookmark:hotelsUpdated', workingHotels);
		// Force a bookmark state refresh for each hotel
		workingHotels.forEach((hotel) => {
			const iffCode = typeof hotel.IffCode === 'string' ? parseInt(hotel.IffCode, 10) : hotel.IffCode;
			EventBus.$emit(
				'bookmark:favoriteChange',
				iffCode,
				workingHotels.some((h) => h.IffCode === hotel.IffCode)
			);
		});
	}, 0);
};

/**
 * Triggers a custom event for favorite persistence change.
 *
 * @param {Number} iffCode iffCode of Hotel that changes favorite
 * @param {Boolean} favorite True if hotel is added; false if it is removed.
 */
function triggerFavoriteChange(iffCode = -1, favorite = false) {
	EventBus.$emit('bookmark:favoriteChange', iffCode, favorite);
}

const getFavoritedHotelFromAnalytics = (bookmarkAnalytics: BookmarkAnalytics, hotelCategory: number): FavoritedHotel => {
	let hotel = null;
	if (bookmarkAnalytics.items && bookmarkAnalytics.items.items[0].item_category2) {
		const { item_id: IffCode, item_name: Name, item_category2: Region } = bookmarkAnalytics.items.items[0];
		hotel = {
			IffCode,
			Name,
			Region,
			Category: hotelCategory,
		};
	} else {
		throw new Error('Analytics Object Error: ' + bookmarkAnalytics);
	}
	return hotel;
};

const addEntryToFavoritePersistence = (bookmarkAnalytics: BookmarkAnalytics, hotelCategory: number) => {
	const newHotel = getFavoritedHotelFromAnalytics(bookmarkAnalytics, hotelCategory);
	getWishlistHotels().then((persistedHotels) => {
		if (!persistedHotels.hotels.find((h) => h.IffCode === newHotel.IffCode)) {
			persistedHotels.hotels.unshift(newHotel);
			bookmarkAnalytics.fireAddToCart();
			updateStorage(persistedHotels.hotels);
			triggerFavoriteChange(newHotel?.IffCode, true);
			// Emit event with updated hotels list to ensure UI updates
			EventBus.$emit('bookmark:hotelsUpdated', persistedHotels.hotels);
		}
	});
};

const removeEntryFromFavoritePersistence = (bookmarkAnalytics: BookmarkAnalytics) => {
	const iff = bookmarkAnalytics.items?.items[0].item_id;
	getWishlistHotels().then((persistedHotels) => {
		const removeElementIndex = persistedHotels.hotels.findIndex((h) => h.IffCode === Number(iff));
		if (removeElementIndex > -1) {
			bookmarkAnalytics.fireRemoveFromCart();
			persistedHotels.hotels.splice(removeElementIndex, 1);
			updateStorage(persistedHotels.hotels);
			triggerFavoriteChange(iff, false);
			// Emit event with updated hotels list to ensure UI updates
			EventBus.$emit('bookmark:hotelsUpdated', persistedHotels.hotels);
		}
	});
};

const isFavorite = (iffCode: number) => getWishlistHotels().then(({ hotels }) => hotels.findIndex((c) => c.IffCode === iffCode) > -1);

export const migrateWishlistPersistence = async () => {
	const hotelList = loadLocalStorageFavoriteHotel();
	if (hotelList.hotels.length === 0) return;
	let hotels: FavoritedHotel[] = [];
	const ids = hotelList.hotels.map((hotel) => hotel.IffCode);
	try {
		hotels = await hotelListService.get(ids);
		updateStorage(hotels);
	} catch {
		// do nothing
	}
	return hotels;
};

function saveFavoritesHotel(newFavoriteHotels: FavoritedHotel[]): void {
	const payload: PersistedFavoriteHotel = {
		updatedAt: Date.now(),
		createdAt: Date.now(),
		hotels: newFavoriteHotels,
	};
	window.localStorage.setItem(getTTFavoritePersistenceKey(), JSON.stringify(payload));
}

export {
	addEntryToFavoritePersistence,
	removeEntryFromFavoritePersistence,
	getWishlistHotels,
	isFavorite,
	triggerFavoriteChange,
	updateStorage,
};
