import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "feature-list" }
const _hoisted_2 = {
  key: 0,
  class: "feature-list__duration"
}
const _hoisted_3 = {
  key: 1,
  class: "feature-list__duration"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "offer-feature__text"
}
const _hoisted_6 = {
  key: 1,
  class: "offer-feature__text"
}
const _hoisted_7 = ["href"]

import { computed, onMounted, ref, watch } from 'vue';
import OfferFeature from '@lmt-rpb/OfferFeature/OfferFeature.vue';
import FlexFeature from '@lmt-rpb/FlexFeature/FlexFeature.vue';
import type { PageLocation } from '@global-js/constants';
import { NDASH } from '@global-js/constants';
import type { OfferData } from '@/interfaces/checkout/offer-data';
import { useStore } from '@/components/common/store';
import type { ViewType } from '@/js/data/view-types';
import viewTypes from '@/js/data/view-types';
import { normalizeHotelAttributes } from '@/js/utils/arrayUtils';
import { boardTypes } from '@/js/data/index';
import { convertFeaturesIntoArray, pluralize } from '@/js/utils/utils';
import { formatDate } from '@utils/dateUtils';
import type { MergedOfferItemData } from '@/interfaces/offer';
import type { ShoppingCart } from '@/interfaces/checkout/shopping-cart';
import { formatPrice } from '@/js/utils/priceUtils';
import type { DetailList, FeatureOfferType } from '@/interfaces/components/featureListData';
import InfoTooltip from '../InfoTooltip/InfoTooltip.vue';
import type { Feature } from '../OfferFeature/offer-feature';
import { getBaseUrl, isRpbClient } from '@utils/environmentUtils';

type Props = {
	offer: FeatureOfferType;
	isFirstFeatureBold?: boolean;
	location?: PageLocation;
	isLongDate?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FeatureList',
  props: {
    offer: {},
    isFirstFeatureBold: { type: Boolean },
    location: {},
    isLongDate: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const voucherConditionsLink = computed(() => {
	let url = getBaseUrl() + '/gutschein-einloesen/';
	if (isRpbClient()) {
		//only rb has this "sub"-path
		url += 'geld-zurueck-gutschein/';
	}
	return url + '#bedingungen';
});

const duration = ref<number | string>(0);
const seaView = ref<string>('');
const offerData = ref<FeatureOfferType>({});
const offerFeaturesArray = ref<Feature[]>([]);
const boardType = ref<string>('');
const showTooltip = ref(false);
const flexStornoChecked = computed(() => store.state.checkout.FlexStornoChecked);
const isCheckoutLocation = ['checkoutInfoBox', 'checkoutSummary', 'thankyouPage'].includes(props.location ?? '');

const isHotelOnly = !isCheckoutLocation ? computed((): boolean => store.state.searchMask.onlyHotel) : ref(false);

function isNumber(value: any): boolean {
	return typeof value === 'number' && !Number.isNaN(value);
}

function computeDuration() {
	let durationValue = 0;
	const dateHelper = (startDate?: Date, endDate?: Date, duration?: number) => {
		if (!startDate || !endDate) {
			return NDASH;
		}
		const durationLong = duration ? ` (${pluralize(duration, 'Tag', 'Tage')})` : '';
		return `${formatDateLong(startDate)} ${NDASH} ${formatDateLong(endDate)}${durationLong}`;
	};

	if (props.isLongDate && isOfferData(props.offer)) {
		return dateHelper(
			new Date((props.offer as OfferData).Offer.StartDate),
			new Date((props.offer as OfferData).Offer.EndDate),
			props.offer.Offer.StayDuration
		);
	}

	if (isOfferData(props.offer)) durationValue = props.offer.Offer.StayDuration || props.offer.Offer.OvernightStays || 0;
	else if (isMergedOfferItemData(props.offer)) durationValue = props.offer.duration || props.offer.StayDuration || 0;
	else if (isShoppingCart(props.offer)) {
		if (props.isLongDate) {
			const travel = props.offer.Travel;
			return dateHelper(new Date(travel.StartDate), new Date(travel.EndDate), travel.Duration);
		}
		durationValue = props.offer.Travel.Duration || 0;
	}
	return durationValue;
}

function computeSeaView() {
	let seaViewValue = '';
	if (isOfferData(props.offer)) seaViewValue = props.offer.Offer.Room.View || '';
	else if (isMergedOfferItemData(props.offer)) seaViewValue = props.offer.Room.View || '';
	else if (isShoppingCart(props.offer)) seaViewValue = props.offer.Travel.Hotel.RoomView || '';
	return seaViewValue;
}

function computeOffer() {
	let offerValue = {};
	if (isOfferData(props.offer)) offerValue = props.offer.Offer as unknown as OfferData;
	else if (isShoppingCart(props.offer)) offerValue = props.offer as ShoppingCart;
	else if (isMergedOfferItemData(props.offer)) offerValue = props.offer as MergedOfferItemData;
	return offerValue;
}

function computeOfferFeaturesArray(flexStornoSelected?: boolean) {
	const isThankYouPage = props.location === 'thankyouPage';
	const excludeFlexCancellation = isThankYouPage && !props.offer?.Pricing?.OptionalFlexRate?.Amount;

	const offerFeatures = {
		...(offerData.value?.OfferFeatures || {}),
		...(offerData.value?.Travel?.OfferFeatures || {}),
	};

	if (offerData.value?.Travel?.OfferFeatures && offerData.value?.OptionalFlexRate?.Description) {
		offerFeatures.OptionalFlexRate = offerData.value.OptionalFlexRate.Description;
	}

	if (excludeFlexCancellation) {
		delete offerFeatures.OptionalFlexRate;
	}

	const featuresArray = convertFeaturesIntoArray(offerFeatures, store.state.checkout.FlexStorno, flexStornoSelected, isThankYouPage);

	return featuresArray
		? featuresArray.map((feature, index) => ({
				id: index,
				checked: true,
				text: feature.title,
				feature: { title: feature.title, description: feature.description },
			}))
		: [];
}

function computeBoardType() {
	return (
		boardTypes.find((type) => {
			const mealType = isShoppingCart(props.offer) ? props.offer.Travel.Hotel.MealType : offerData.value.BoardType;
			return type.value.toLowerCase() === mealType.toLowerCase();
		})?.label || ''
	);
}

function getCommonList(RoomName: string, OfferIncludes: string[], OfferForLimitedMobility: boolean, TransferInfos?: string[]): DetailList {
	const isTransferIncluded = OfferIncludes?.includes('transfer') || TransferInfos?.includes('TransferIncluded');
	const isRentalCarIncluded = OfferIncludes?.includes('rentalcar');

	return [
		{
			id: -1,
			checked: true,
			text: `1x ${normalizeHotelAttributes(RoomName)}`,
			icon: ['fa-solid', 'fa-bed'],
		},
		{
			id: 20,
			checked: true,
			text: boardType.value,
			icon: ['fa-solid', 'fa-utensils'],
		},
		{
			id: 30,
			checked: isTransferIncluded,
			text: isTransferIncluded ? 'Inkl. Hoteltransfer' : 'Ohne Hoteltransfer',
			icon: isTransferIncluded ? ['fa-solid', 'fa-bus-simple'] : ['fa-kit', 'fa-no-transfer'],
		},
		...((isRentalCarIncluded
			? [
					{
						id: 31,
						checked: true,
						text: 'Mietwagen inklusive',
						icon: ['fa-solid', 'fa-car'],
					},
				]
			: []) satisfies DetailList),
		...((OfferIncludes?.includes('railandfly')
			? [
					{
						id: 40,
						checked: true,
						text: 'Zug zum Flug (innerh. Deutschlands)',
						icon: ['fa-solid', 'fa-train-subway'],
					},
				]
			: []) satisfies DetailList),
		...((isCheckoutLocation
			? [
					{
						id: 35,
						checked: OfferForLimitedMobility,
						text: OfferForLimitedMobility
							? 'Geeignet für Personen mit eingeschränkter Mobilität.'
							: 'Nicht geeignet für Personen mit eingeschränkter Mobilität.',
						icon: OfferForLimitedMobility ? ['fa-solid', 'fa-wheelchair'] : ['fa-solid', 'fa-triangle-exclamation'],
					},
				]
			: []) satisfies DetailList),
	];
}

const detailList = computed<DetailList>(() => {
	const flexStorno = flexStornoChecked.value;
	const isNotUnverifiedOffer = props.location !== 'unverifiedOffer';
	const isCheckoutSummary = props.location === 'checkoutSummary';
	const isThankYouPage = props.location === 'thankyouPage';

	let list: DetailList = [];

	if (isShoppingCart(props.offer)) {
		const offer = props.offer;
		if (offer && offer.Travel.TravelType === 'Accommodation') {
			isHotelOnly.value = true;
		}
		if (offer && offer.Travel && offer.Travel.Hotel && offer.Travel.Hotel.RoomName) {
			const {
				Hotel: { RoomName },
				OfferIncludes = [],
				OfferForLimitedMobility,
			} = offer.Travel;
			list = getCommonList(RoomName, OfferIncludes, OfferForLimitedMobility);
		}
	} else {
		const offer = offerData.value;
		if (offer && offer.Room && offer.Room.Name) {
			const {
				Room: { Name: RoomName },
				OfferIncludes,
				OfferForLimitedMobility,
				TransferInfos,
			} = offer;
			list = getCommonList(RoomName, OfferIncludes, OfferForLimitedMobility, TransferInfos);
		}
	}

	if (isNotUnverifiedOffer) {
		list.push({ id: 50, checked: true, text: '100 € Gutschein', icon: ['fa-solid', 'fa-badge-percent'] });
	}

	if (isCheckoutSummary && isOfferData(props.offer) && props.offer.Offer.OutboundFlightSegments) {
		const { OutboundFlightSegments, InboundFlightSegments } = props.offer.Offer;
		list.push({
			id: -3,
			checked: true,
			text: 'inkl. Flug ab ' + OutboundFlightSegments[0].Departure.Airport,
			icon: ['fa-solid', 'fa-plane'],
		});
		const date = duration.value === 1 ? '1 Tag' : duration.value + ' Tage';
		const dateDeparture = formatDateLong(OutboundFlightSegments[0].Departure.Date);
		const dateInbound = formatDateLong(InboundFlightSegments[InboundFlightSegments.length - 1].Arrival.Date);
		list.push({ id: -2, checked: true, text: `${date}, ${dateDeparture} - ${dateInbound}`, icon: ['fa-regular', 'fa-calendar-range'] });
	}

	viewTypes.forEach((viewType: ViewType) => {
		if (seaView.value === viewType.value) {
			list.push({ id: 11, checked: true, text: viewType.label, icon: ['fa-solid', 'fa-water'] });
		}
	});

	if (!flexStorno && !isThankYouPage) {
		list = list.filter((item) => item.id !== 60);
	}

	if (isHotelOnly.value || (isOfferData(props.offer) && props.offer.Offer.TravelType === 'Accommodation')) {
		list = list.filter((item) => item.text !== 'Ohne Hoteltransfer');
	}

	return organizeDetailList(list);
});

function organizeDetailList(list: DetailList): DetailList {
	// Sort by checked first, then by id
	list.sort((a, b) => {
		if (a.checked !== b.checked) {
			return a.checked ? -1 : 1;
		}
		return a.id - b.id;
	});

	// Transfer (included or not) should always come after the catering option
	const cateringIndex = list.findIndex((item) => item.id === 20);
	const transferIndex = list.findIndex((item) => item.id === 30);

	if (cateringIndex !== -1 && transferIndex !== -1) {
		if (transferIndex !== cateringIndex + 1) {
			const [transferItem] = list.splice(transferIndex, 1);
			list.splice(cateringIndex + 1, 0, transferItem);
		}
	}

	return list;
}

const toggleTooltip = (text: string, state: boolean) => {
	if (text === '100 € Gutschein') {
		showTooltip.value = state;
	}
};

const isOfferData = (data?: FeatureOfferType): data is OfferData => (data as OfferData).Offer !== undefined;
const isShoppingCart = (data?: FeatureOfferType): data is ShoppingCart => (data as ShoppingCart).ShoppingCartID !== undefined;
const isMergedOfferItemData = (data?: FeatureOfferType): data is MergedOfferItemData => (data as MergedOfferItemData).OfferID !== undefined;

const formatDateLong = (date: string | Date | undefined) =>
	date
		? formatDate(date, {
				weekday: 'short',
				month: '2-digit',
				day: '2-digit',
				year: '2-digit',
			}).replace(',', '')
		: '.';

onMounted(() => {
	duration.value = computeDuration();
	seaView.value = computeSeaView();
	offerData.value = computeOffer();
	offerFeaturesArray.value = computeOfferFeaturesArray();
	boardType.value = computeBoardType();
});

watch(flexStornoChecked, (value) => {
	offerFeaturesArray.value = computeOfferFeaturesArray(value);
});

watch(
	() => store.state.checkout.FlexStorno,
	() => {
		offerFeaturesArray.value = computeOfferFeaturesArray();
	}
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!(_ctx.location === 'checkoutSummary') && !_ctx.isLongDate && isNumber(duration.value))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(pluralize)(duration.value ?? 0, 'Tag', 'Tage')), 1 /* TEXT */))
      : (_ctx.isLongDate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(duration.value), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
    (detailList.value)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailList.value, (feature, index) => {
            return (_openBlock(), _createBlock(OfferFeature, {
              key: index,
              feature: feature,
              "feature-bold": _ctx.isFirstFeatureBold && index === 0,
              location: _ctx.location,
              onMouseover: ($event: any) => (toggleTooltip(feature.text, true)),
              onMouseleave: ($event: any) => (toggleTooltip(feature.text, false))
            }, {
              default: _withCtx(() => [
                (feature.text !== '100 € Gutschein')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(normalizeHotelAttributes)(feature.text)), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                (feature.text == '100 € Gutschein')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createTextVNode(_toDisplayString(_unref(formatPrice)(100, 'EUR')) + " Gutschein ", 1 /* TEXT */),
                      _createVNode(InfoTooltip, {
                        class: "offer-feature__tooltip",
                        "show-tooltip-parent": showTooltip.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Mit Ihrer Reisebuchung erhalten Sie als Dankeschön einen " + _toDisplayString(_unref(formatPrice)(100, 'EUR')) + " Gutschein für Ihre nächste Reise. ", 1 /* TEXT */),
                          _createElementVNode("div", null, [
                            _createElementVNode("a", {
                              href: voucherConditionsLink.value,
                              target: "_blank"
                            }, " Hier gehts zu den Gutscheinbedingungen. ", 8 /* PROPS */, _hoisted_7)
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["show-tooltip-parent"])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["feature", "feature-bold", "location", "onMouseover", "onMouseleave"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(offerFeaturesArray.value, (feature, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(FlexFeature, {
          feature: feature.feature
        }, null, 8 /* PROPS */, ["feature"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}
}

})