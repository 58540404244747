import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "month__title"
}
const _hoisted_2 = { class: "month__table" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = ["data-date", "onClick", "onKeydown"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

import { formatDate, dateDiff, now, shortifyWeekday } from '@utils/dateUtils';
import { computed } from 'vue';
import type { BestAccommodationOfferForCheckInDate } from '@/interfaces/api/v1-best-accommodation-offer-for-check-in-date';
import { formatPrice, getTotalPrice } from '@/js/utils/priceUtils';
import type { SelectionType } from '@/components/common/types';
import { rangeArray } from '@/js/utils/arrayUtils';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';
import { useTranslations } from '@helper/composables/translations';

interface Props {
	selection: SelectionType;
	year: number;
	month: number;
	horizontal: boolean;
	offers?: BestAccommodationOfferForCheckInDate[];
	cheapestOffer?: number;
	bestPriceCalendar?: boolean;
}
type WeekElement = { date: Date; price?: number; pricing?: number; currencyCode?: string } | null;


export default /*@__PURE__*/_defineComponent({
  __name: 'Month',
  props: {
    selection: {},
    year: {},
    month: {},
    horizontal: { type: Boolean },
    offers: { default: () => [] },
    cheapestOffer: { default: -1 },
    bestPriceCalendar: { type: Boolean, default: false }
  },
  emits: ['select'],
  setup(__props: any, { emit: __emit }) {

const { translate } = useTranslations();

const getClass = (day: WeekElement) => {
	if (!day) {
		return {
			'is-empty': true,
		};
	}
	const { date, price } = day;
	return {
		'is-disabled': isDisabled(day),
		'is-selected': isSelected(date),
		'is-from-selection': isFromSelection(date),
		'is-to-selection': isToSelection(date),
		'is-cheapest': props.cheapestOffer === price,
	};
};

const props = __props;
const emit = __emit;

const daysInCalenderMonth = computed(() =>
	rangeArray(-firstWeekDay.value - 6, daysInMonth.value).map((day: number) => (day <= 0 ? null : day))
);

const daysForWeek = (week: number): (WeekElement | null)[] => {
	const days = daysInCalenderMonth.value.slice((week - 1) * 7, week * 7);
	if (days.every((d) => d === null)) {
		return [];
	}
	return days.map((d) => {
		if (d === null) {
			return null;
		}
		const date = new Date(props.year, props.month, d);
		const foundOffer = props.offers.find((offer) => !dateDiff(offer.StartDate, date));
		if (foundOffer) {
			const totalPrice = getTotalPrice(foundOffer.Pricing ?? {});
			return {
				date,
				price: Math.round(foundOffer.Price),
				pricing: Math.round(totalPrice),
				currencyCode: foundOffer.CurrencyCode,
			};
		}
		return { date };
	});
};

const { isDesktop } = storeToRefs(useBreakpointStore());
const daysInMonth = computed((): number => new Date(props.year, props.month + 1, 0).getDate());
const weekAmount = computed((): number => Math.ceil(daysInCalenderMonth.value.length / 7));

const daysOfTheWeek = computed(() => [
	translate('calendar.daysOfWeek.monday'),
	translate('calendar.daysOfWeek.tuesday'),
	translate('calendar.daysOfWeek.wednesday'),
	translate('calendar.daysOfWeek.thursday'),
	translate('calendar.daysOfWeek.friday'),
	translate('calendar.daysOfWeek.saturday'),
	translate('calendar.daysOfWeek.sunday'),
]);

const title = computed((): string => {
	const monthName = formatDate(new Date(2000, props.month, 1), { month: 'long' });
	return `${monthName} ${props.year}`;
});

const firstWeekDay = computed((): number => new Date(props.year, props.month).getDay() - 1); // Minus 1 because our week in the calendar starts always on monday

const isDisabled = (day: WeekElement): boolean => {
	if (!day) {
		return true;
	}
	const today = now(true);
	return day.date.getTime() <= +today || (day?.price === undefined && props.bestPriceCalendar);
};

const onSelect = (day: WeekElement) => {
	if (!day) {
		return;
	}
	if (!isDisabled(day)) {
		emit('select', day?.date);
	}
};

const isSelected = (day?: Date): boolean => {
	if (!props.selection.from || !props.selection.to || !day) {
		return false;
	}
	return (isFromSelection(day) && isToSelection(day)) || (props.selection.from <= day.getTime() && props.selection.to >= day.getTime());
};

const isFromSelection = (day?: Date): boolean => {
	if (!props.selection.from || !day) {
		return false;
	}

	return new Date(props.selection.from).toDateString() === day?.toDateString();
};

const isToSelection = (day?: Date): boolean => {
	if (!props.selection.to || !day) {
		return false;
	}

	return new Date(props.selection.to).toDateString() === day.toDateString();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["month", { 'best-price-calendar': _ctx.bestPriceCalendar }])
  }, [
    (!_ctx.bestPriceCalendar)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(title.value), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("table", _hoisted_2, [
      (_ctx.horizontal || (!_unref(isDesktop) && !_ctx.bestPriceCalendar))
        ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(daysOfTheWeek.value, (day, key) => {
              return (_openBlock(), _createElementBlock("th", {
                key: key,
                "aria-label": day,
                class: "month__weekday",
                scope: "col"
              }, _toDisplayString(_unref(shortifyWeekday)(day)), 9 /* TEXT, PROPS */, _hoisted_4))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(weekAmount.value, (week) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: `${_ctx.year}-${_ctx.month}-week-${week}`
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(daysForWeek(week), (day, dayIndex) => {
            return (_openBlock(), _createElementBlock("td", {
              key: `${_ctx.year}-${_ctx.month}-${dayIndex}`,
              "data-date": day?.date.getTime(),
              class: _normalizeClass([getClass(day), "month__day-cell"]),
              onClick: _withModifiers(($event: any) => (onSelect(day)), ["prevent","stop"]),
              onKeydown: [
                _withKeys(_withModifiers(($event: any) => (onSelect(day)), ["prevent","stop"]), ["space"]),
                _withKeys(_withModifiers(($event: any) => (onSelect(day)), ["prevent","stop"]), ["enter"])
              ]
            }, [
              _createTextVNode(_toDisplayString(day?.date?.getDate()) + " ", 1 /* TEXT */),
              (_ctx.bestPriceCalendar)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["month__day-price", { isCHF: day?.currencyCode === 'CHF' }])
                  }, [
                    (!isDisabled(day))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(formatPrice)(day?.pricing ?? null, day?.currencyCode)), 1 /* TEXT */))
                      : (_openBlock(), _createElementBlock("span", _hoisted_7, "   "))
                  ], 2 /* CLASS */))
                : _createCommentVNode("v-if", true)
            ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_5))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 2 /* CLASS */))
}
}

})