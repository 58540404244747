import dialogPolyfill from 'dialog-polyfill';
import ModalManager from './modalManager';

export default class Modal {
	modalRef: HTMLDialogElement;

	maxWindowWidthQuery: MediaQueryList | null;

	handleHistoryBack: () => void = () => {};

	constructor(
		modalRef: HTMLDialogElement,
		maxWindowWidth: MediaQueryList | null = window.matchMedia('(min-width: 1300px)'),
		handleHistoryBack: () => void = () => {}
	) {
		this.modalRef = modalRef;
		this.maxWindowWidthQuery = maxWindowWidth;
		this.handleHistoryBack = handleHistoryBack;
		this._init();
	}

	_init() {
		// close modal on native back event
		const isDialogSupported = typeof this.modalRef.showModal === 'function' && this.modalRef.showModal !== undefined;

		// dialog polyfill
		if (!isDialogSupported && !window.HTMLDialogElement) {
			dialogPolyfill.registerDialog(this.modalRef);
		}

		this.maxWindowWidthQuery?.addEventListener('change', () => this._historyBack());
		this.modalRef.addEventListener('keydown', this._handleEscape);

		// The @close method is a own function from the dialog tag element that requires separate handling for android hardware button and swipe to close
		this.modalRef.addEventListener('cancel', this.handleDialogOwnCancle);
	}

	openModal = (): void => {
		if (!this.modalRef || this.modalRef.open) {
			return;
		}

		this.modalRef.showModal();
		window.history.pushState({ checkoutModal: true }, '');
		ModalManager.modalOpenHistory.push(this);
		document.body.style.overflow = 'hidden';
		this.modalRef.style.display = '';
	};

	_historyBack = (): void => {
		ModalManager.closeModalAndChildren(this);
	};

	_animationCallback = (): void => {
		this.modalRef.removeEventListener('animationend', this._animationCallback);
		this.modalRef.classList.remove('closeAnimation');
		this._historyBack();
	};

	handleDialogOwnCancle = (e: Event): void => {
		this.closeModal();
		e.stopImmediatePropagation();
		e.preventDefault();
		e.stopPropagation();
	};

	closeModal = (): void => {
		if (this.maxWindowWidthQuery?.matches) {
			this._historyBack();
		} else if (this.modalRef) {
			this.modalRef.addEventListener('animationend', this._animationCallback);
			this.modalRef.classList?.add('closeAnimation');
		}
	};

	_handleEscape = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			event.preventDefault();
			event.stopPropagation();
			const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
			if (isNotCombinedKey) {
				this.closeModal();
			}
		}
	};

	show() {
		this.modalRef.showModal();
		// Additional logic to notify ModalManager or handle visibility
	}

	hide() {
		this.modalRef.close();
		// Additional logic to notify ModalManager or handle visibility
	}
}
