import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "map-preview-and-modal"
}
const _hoisted_2 = {
  key: 0,
  class: "map-preview-and-modal__map-container"
}
const _hoisted_3 = {
  key: 1,
  class: "map-preview-and-modal__place-holder"
}
const _hoisted_4 = {
  key: 2,
  class: "map-preview-and-modal__uc-layer"
}
const _hoisted_5 = {
  class: "map-preview-and-modal__fullscreen-btn",
  "aria-label": "Vollbild-Karte anzeigen"
}
const _hoisted_6 = {
  id: "static-map-container",
  class: "map-preview-and-modal__static-map"
}
const _hoisted_7 = ["data-srcset"]
const _hoisted_8 = ["media", "data-srcset"]
const _hoisted_9 = ["data-srcset"]
const _hoisted_10 = ["data-src", "alt"]
const _hoisted_11 = {
  key: 0,
  class: "map-preview-and-modal__note"
}

import type { Ref } from 'vue';
import { ref, onMounted, onUnmounted, reactive, onBeforeMount, nextTick, watch } from 'vue';
import type { StaticMaps, MarkerData } from '@interfaces/google-maps';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import MapWithOffers from '@lmt-rpb/MapWithOffers/MapWithOffers.vue';
import Modal from '@/js/modules/modal';
import UserCentrics from '@/js/modules/UserCentrics';
import type { ApiDataTypes } from '@/components/common/types';
import type { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { EventBus } from '@global-js/event-bus';
import { useBreakpointStore } from 'src/store/breakpointsStore';
import { storeToRefs } from 'pinia';

interface Props {
	title: string;
	lat: number;
	lng: number;
	zoom?: number;
	staticMaps: StaticMaps;
	mapsKey: string;
	marker?: boolean;
	customMarkerData?: Array<MarkerData>;
	gaEventLabel?: string;
	showNote?: boolean;
	northwest: { lat: number; lng: number } | null;
	southeast: { lat: number; lng: number } | null;
	hotelRequestBody: ApiDataTypes;
	hotelToShow?: ItemType | null;
	hotelToShowIffCode?: number;
	isHotelPage?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MapPreviewAndModal',
  props: {
    title: {},
    lat: {},
    lng: {},
    zoom: { default: 11 },
    staticMaps: {},
    mapsKey: {},
    marker: { type: Boolean, default: true },
    customMarkerData: { default: () => [] },
    gaEventLabel: {},
    showNote: { type: Boolean, default: true },
    northwest: {},
    southeast: {},
    hotelRequestBody: {},
    hotelToShow: { default: undefined },
    hotelToShowIffCode: { default: undefined },
    isHotelPage: { type: Boolean, default: false }
  },
  setup(__props: any) {



const { isDesktop } = storeToRefs(useBreakpointStore());
const mapModalDiv = ref<HTMLDialogElement>();
const modal: Ref<Modal | null> = ref(null);
const userCentrics = reactive(new UserCentrics('Google Maps'));
const modalOpened = ref(false);
const showUcSecondLayer = ref(false);
let moreInfoButton: Element | null = null;
let observer: MutationObserver;

const mapWithOffers: Ref<InstanceType<typeof MapWithOffers> | null> = ref(null);
const eventsController = new AbortController();

const initializeMap = () => {
	if (!mapWithOffers.value) return;
	mapWithOffers.value.openDefaultInfoWindow();
};

const loadFullscreen = () => {
	modal.value?.openModal();
	modalOpened.value = true;

	if (!userCentrics.consentGiven) {
		handleUserCentrics();
	} else {
		initializeMap();
	}
};

watch(
	() => userCentrics.consentGiven,
	(newValue, oldValue) => {
		if (newValue && !oldValue && modalOpened.value) {
			if (mapWithOffers.value) {
				mapWithOffers.value.$forceUpdate();
				initializeMap();
			}
		}
	}
);

const clickEvent = async () => {
	if (!window.UC_UI) {
		return;
	}
	showUcSecondLayer.value = true;
	// Small delay to ensure container is mounted
	await nextTick(() => {
		const ucContainer = document.querySelector('.map-preview-and-modal__uc-layer');
		const ucRoot = document.querySelector('#usercentrics-root');
		if (!ucContainer || !ucRoot) {
			return;
		}
		// Store original position info
		const originalParent = ucRoot.parentElement;
		const originalNextSibling = ucRoot.nextSibling;

		// Store references for cleanup
		ucContainer.setAttribute('data-uc-parent', originalParent?.id || 'body');
		if (originalNextSibling instanceof Element) {
			ucContainer.setAttribute('data-uc-next-sibling', originalNextSibling.id || '');
		}

		// Move UC to our modal layer
		ucContainer.appendChild(ucRoot);

		// Show the second layer
		window.UC_UI.showSecondLayer();
	});
};

function handleUserCentrics() {
	userCentrics.init();
	userCentrics.blockElement({ S1pcEj_jZX: '#map-usercentrics' });

	// Handle the more info button separately
	observer = new MutationObserver(() => {
		moreInfoButton = document.querySelector('.uc-embedding-more-info');
		if (!moreInfoButton) {
			return;
		}
		moreInfoButton.addEventListener('click', clickEvent, { signal: eventsController.signal });
	});

	observer.observe(document.body, {
		childList: true,
		subtree: true,
	});
}

const closeModal = () => {
	modalOpened.value = false;
	showUcSecondLayer.value = false;
	remountedUserCentrics();
	modal.value?.closeModal();
};

const closeModalKey = () => {
	modalOpened.value = false;
	showUcSecondLayer.value = false;
};

const remountedUserCentrics = () => {
	const ucContainer = document.querySelector('.map-preview-and-modal__uc-layer');
	const ucRoot = document.querySelector('#usercentrics-root');

	if (ucContainer && ucRoot) {
		const parentId = ucContainer.getAttribute('data-uc-parent');
		const nextSiblingId = ucContainer.getAttribute('data-uc-next-sibling');

		// Get the original parent (fallback to body if not found)
		const originalParent = parentId && parentId !== 'body' ? document.getElementById(parentId) : document.body;

		// Get the original next sibling
		const nextSibling = nextSiblingId ? document.getElementById(nextSiblingId) : null;

		if (originalParent) {
			if (nextSibling) {
				originalParent.insertBefore(ucRoot, nextSibling);
			} else {
				originalParent.appendChild(ucRoot);
			}
		}
	}

	if (window.UC_UI) {
		window.UC_UI.closeCMP();
	}

	showUcSecondLayer.value = false;
	moreInfoButton?.removeEventListener('click', clickEvent);
};

const handleCloseModalKey = (event: KeyboardEvent | PopStateEvent) => {
	if (event instanceof KeyboardEvent && event.key === 'Escape' && modalOpened.value) {
		event.preventDefault();
		event.stopPropagation();
		closeModal();
	}
	if (event instanceof PopStateEvent && modalOpened.value) {
		event.preventDefault();
		// needed for mobile (android / ios)
		if (!userCentrics.consentGiven || !isDesktop.value) {
			closeModalKey();
			remountedUserCentrics();
		} else {
			closeModal();
		}
	}
};

onBeforeMount(() => {
	EventBus.$on('hotel:openMap', loadFullscreen);
	if (!userCentrics.consentGiven) {
		handleUserCentrics();
	}

	// Use capture phase to ensure our handler runs first
	document.addEventListener('keydown', handleCloseModalKey, { signal: eventsController.signal, capture: true });
	window.addEventListener('popstate', handleCloseModalKey, { signal: eventsController.signal, capture: true });
});

onMounted(() => {
	if (mapModalDiv.value) {
		modal.value = new Modal(mapModalDiv.value);
	}
});

onUnmounted(() => {
	closeModal();
	if (observer) {
		observer.disconnect();
	}
	EventBus.$off('hotel:openMap', loadFullscreen);
	eventsController.abort();
});

return (_ctx: any,_cache: any) => {
  return (_ctx.mapsKey)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("dialog", {
          ref_key: "mapModalDiv",
          ref: mapModalDiv,
          class: "map-preview-and-modal__modal"
        }, [
          (modalOpened.value && userCentrics.consentGiven)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(MapWithOffers, {
                  id: _ctx.title,
                  ref_key: "mapWithOffers",
                  ref: mapWithOffers,
                  lat: _ctx.lat,
                  lng: _ctx.lng,
                  northwest: _ctx.northwest,
                  southeast: _ctx.southeast,
                  "hotel-request-body": _ctx.hotelRequestBody,
                  "is-hotel-page": _ctx.isHotelPage,
                  "hotel-to-show-iff-code": _ctx.hotelToShowIffCode,
                  "hotel-to-show": _ctx.hotelToShow
                }, null, 8 /* PROPS */, ["id", "lat", "lng", "northwest", "southeast", "hotel-request-body", "is-hotel-page", "hotel-to-show-iff-code", "hotel-to-show"])
              ]))
            : (modalOpened.value && !userCentrics.consentGiven)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
                  _createElementVNode("div", {
                    id: "map-usercentrics",
                    class: "map-preview-and-modal__usercentrics"
                  }, [
                    _createElementVNode("div", { class: "map-preview-and-modal__usercentrics-content" }, [
                      _createCommentVNode(" User Centrics replace Container")
                    ])
                  ], -1 /* HOISTED */)
                ])))
              : _createCommentVNode("v-if", true),
          (showUcSecondLayer.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("v-if", true),
          _createElementVNode("button", {
            class: "map-preview-and-modal__close-btn",
            "aria-label": "Vollbild-Karte schließen",
            onClick: _withModifiers(closeModal, ["prevent"])
          }, [
            _createVNode(BaseIcon, {
              name: "close",
              class: "map-preview-and-modal__close-icon"
            })
          ])
        ], 512 /* NEED_PATCH */),
        _renderSlot(_ctx.$slots, "default", { loadFullscreen: loadFullscreen }, () => [
          _createElementVNode("div", {
            id: "map-preview",
            class: "map-preview-and-modal__preview",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (loadFullscreen()), ["prevent"]))
          }, [
            _createElementVNode("button", _hoisted_5, [
              _createVNode(BaseIcon, {
                name: "fullscreen",
                class: "map-preview-and-modal__fullscreen-icon"
              })
            ]),
            _createElementVNode("picture", _hoisted_6, [
              (_ctx.staticMaps.staticgmap_992)
                ? (_openBlock(), _createElementBlock("source", {
                    key: 0,
                    media: "(min-width: 544px)",
                    "data-srcset": _ctx.staticMaps.staticgmap_992
                  }, null, 8 /* PROPS */, _hoisted_7))
                : _createCommentVNode("v-if", true),
              (_ctx.staticMaps.staticgmap_544)
                ? (_openBlock(), _createElementBlock("source", {
                    key: 1,
                    media: _ctx.staticMaps.staticgmap_420 ? '(min-width: 420px)' : '',
                    "data-srcset": _ctx.staticMaps.staticgmap_544
                  }, null, 8 /* PROPS */, _hoisted_8))
                : _createCommentVNode("v-if", true),
              (_ctx.staticMaps.staticgmap_420)
                ? (_openBlock(), _createElementBlock("source", {
                    key: 2,
                    "data-srcset": _ctx.staticMaps.staticgmap_420
                  }, null, 8 /* PROPS */, _hoisted_9))
                : _createCommentVNode("v-if", true),
              _createElementVNode("img", {
                "data-src": _ctx.staticMaps.staticgmap_544 || _ctx.staticMaps.staticgmap_420,
                src: "data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw==",
                alt: `Google Map von ${_ctx.title}`,
                class: "rpb_lazy map-preview-and-modal__static-map-img"
              }, null, 8 /* PROPS */, _hoisted_10)
            ])
          ])
        ]),
        (_ctx.showNote)
          ? (_openBlock(), _createElementBlock("span", _hoisted_11, " (Positionsangaben ohne Gewähr) "))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}
}

})