<template>
	<section class="hotel-map">
		<button
			class="hotel-map__container"
			aria-label="Open full-screen map"
			v-bind="gaAttributes"
			@click="openFullScreenMap"
		>
			<MapImage
				class="hotel-map__location-map rpb_lazy"
				alt="map"
			/>
			<span class="hotel-map__button-text">Zur Karte</span>
		</button>
		<div
			v-if="location"
			class="hotel-map__location"
			aria-label="Hotel location"
		>
			<i class="fa-solid fa-location-dot hotel-map__icon"></i>
			<span class="hotel-map__location-text">{{ location }}</span>
		</div>
	</section>
</template>
<script setup lang="ts">
import MapImage from './internals/MapImage.vue';

interface Props {
	location?: string;
	gaAttributes?: Record<string, string | number>;
}

const emit = defineEmits(['clickHotelMap']);
defineProps<Props>();

const openFullScreenMap = () => {
	emit('clickHotelMap');
};
</script>
<style scoped lang="scss">
.hotel-map {
	&__container {
		position: relative;
		max-width: 100%;
		height: 19.6rem;
		min-width: 30rem;
		background-color: none;
		border-radius: $border-radius-large;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		cursor: pointer;
		padding: 0;

		& * {
			pointer-events: none;
		}
	}

	&__location-map {
		max-width: 100%;
	}

	&__button-text {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		padding: 0.8rem 2.4rem;
		background-color: $color-primary;
		color: $color-white;
		font-weight: $font-weight-bold;
		border-radius: $border-radius-button;
		font-size: $font-small-1;
	}

	&__icon {
		font-size: $font-medium-1;
		color: $color-primary;
	}

	&__location {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin-top: 1rem;
		margin-left: 0.8rem;
		font-size: $font-small-3;
	}
}
</style>
