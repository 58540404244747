<template>
	<DropdownFilterType
		ref="dropdown"
		v-model:search-term="filterExpression"
		class="airport-filter"
		title="Abflughafen"
		icon="flightStart"
		modal-title="Flughafen"
		apply-button-text="Übernehmen"
		cancel-button-text="Zurücksetzen"
		:reset-instead-of-cancel="true"
		:selected-value="selectedOptionsConcat"
		:with-menu-header="false"
		:button-disabled="!!notFoundMessage"
		:with-cancel-button="true"
		show-buttons
		searchable
		emit-on-ok
		:is-focused="isFocused"
		:show-toggle-icon="false"
		@dropdown-filter-type:apply="applyChanges"
		@dropdown-filter-type:abort="cancel"
		@dropdown-filter-type:clear="clearSelectedOptions"
		@DropdownFilterType:focusedIn="focusIn"
		@DropdownFilterType:focusedOut="focusOut"
	>
		<template #default>
			<p
				v-if="notFoundMessage"
				class="airport-filter__not-found"
			>
				{{ notFoundMessage }}
			</p>
			<template v-else>
				<div
					id="airport-container"
					class="airport-filter"
				>
					<div
						v-if="selectedAirports.length"
						class="airport-filter__selected-options"
					>
						<ChipBase
							v-for="{ label, value } of selectedAirports"
							:key="value"
							:as="'div'"
							:with-delete="true"
							:thin="true"
							@click.stop="() => removeSelection(value)"
							@delete-click.stop="() => removeSelection(value)"
						>
							<template #label>
								{{ label }} <span class="airport-filter__selected-options--thin">({{ value }})</span>
							</template>
						</ChipBase>
					</div>
					<div
						class="airport-filter-country-container"
						:class="{ 'has-selection': selectedAirports.length }"
						@scroll="focusOut"
					>
						<div
							v-for="[country, entries] in filteredItems"
							:key="country"
							class="airport-filter-country"
							:class="{ 'filter-active': filterExpression }"
						>
							<div
								v-if="!filterExpression"
								class="airport-filter-country-name"
								@click="toggleCountry(country)"
							>
								<span>{{ country }}</span>

								<i
									class="airport-filter-country-name__icon fa-solid fa-lg"
									:class="{ ['fa-chevron-' + ((expanded.get(country) ?? false) ? 'up' : 'down')]: true }"
								/>
							</div>
							<TransitionExpand :show-content="expanded.get(country) || !!filterExpression">
								<CheckBox
									v-for="(airport, index) in entries"
									:key="airport.value"
									v-model="selectedOptionsProxy"
									:label="airport.label"
									:custom-value="airport.value"
									class="airport-filter__checkbox"
									:class="{ 'first-item': index === 0 }"
									@click="selectAirportInCountry(airport, country)"
								>
									<template #default>
										<p class="airport-filter__checkbox-label">
											<b class="airport-filter__checkbox-label--strong">{{ airport.label }}</b>
											&nbsp;({{ airport.value }})
										</p>
									</template>
								</CheckBox>
							</TransitionExpand>
						</div>
					</div>
				</div>
			</template>
		</template>
	</DropdownFilterType>
</template>

<script setup lang="ts">
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import CheckBox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from '@/components/common/store';
import { type ClientType } from '@/interfaces/common';
import { type Airport, findAirportByKey, getMappedAirportOptions } from '../../composables/airportOptions';
import TransitionExpand from '@lmt-rpb/TransitionExpand/TransitionExpand.vue';
import type { GERMAN_COUNTRY_NAMES } from '@global-js/constants';
import ChipBase from '@lmt-rpb/Atoms/ChipBase/ChipBase.vue';

type Props = {
	disabled?: boolean;
	client?: ClientType;
	resetInsteadOfCancel?: boolean;
};
function removeSelection(value: string) {
	selectedOptionsProxy.value = selectedOptionsProxy.value.filter((val) => val !== value);
}
const props = withDefaults(defineProps<Props>(), { client: 'at' });

const dropdown = ref<InstanceType<typeof DropdownFilterType>>();
const focusOut = () => {
	dropdown.value?.modal?.input?.blur();
	dropdown.value?.formField?.input?.blur();
	isFocused.value = false;
};
const focusIn = () => (isFocused.value = true);

const selectAirportInCountry = (airport: Airport, country: GERMAN_COUNTRY_NAMES) => {
	if (!selectedOptionsProxy.value.some((a) => airport.value === a)) {
		//expand if its selected
		toggleCountry(country, true);
	}
	clearTerm();
};
const toggleCountry = (country: GERMAN_COUNTRY_NAMES, value = !(expanded.value.get(country) ?? false)) => {
	expanded.value.set(country, value);
};

const expanded = ref(
	new Map(
		Array.from(getMappedAirportOptions(props.client)).map(([country], index) => {
			return [country, index === 0];
		})
	)
);

const isFocused = ref(false);

const store = useStore();
const sortedAirportsByCountry = getMappedAirportOptions(props.client);

const filteredItems = computed(() => {
	const t = filterExpression.value.toLowerCase().trim();
	return Array.from(sortedAirportsByCountry.entries())
		.map(([country, entries]) => [
			country,
			entries.filter(({ label, value }) => {
				return label.toLowerCase().startsWith(t) || value.toLowerCase().startsWith(t);
			}),
		])
		.filter(([, entries]) => entries.length > 0) as [GERMAN_COUNTRY_NAMES, Airport[]][];
});
const notFoundMessage = computed(() => (filteredItems.value.length === 0 ? 'Für deine Suche gibt es leider keine passenden Filter.' : ''));
const filterExpression = ref('');
const selectedOptions = computed(() => store.state.searchMask?.departure ?? []);

const selectedOptionsProxy = ref(selectedOptions.value);
const applyChanges = () => {
	manageCountryListOpenCloseState();
	store.commit('searchMask/updateFormData', { departure: selectedOptionsProxy.value });
};
const cancel = () => {
	selectedOptionsProxy.value = store.state.searchMask?.departure ?? [];
	manageCountryListOpenCloseState();
};

const clearTerm = () => (filterExpression.value = '');
const clearSelectedOptions = () => {
	selectedOptionsProxy.value = [];
};

const selectedAirports = computed(() => {
	const airports = Array.from(sortedAirportsByCountry.values()).flatMap((entries) => entries);
	return selectedOptionsProxy.value
		.map((entry) => airports.find((a) => a.value === entry))
		.filter((airport): airport is Airport => airport !== undefined);
});

const selectedOptionsConcat = computed(() => {
	return selectedOptions.value
		.map((key) => {
			const airport = findAirportByKey(key);
			return airport ? airport.label : key;
		})
		.join(', ');
});
const manageCountryListOpenCloseState = () => {
	const firstCountry = Array.from(expanded.value.keys())[0];
	Array.from(expanded.value.keys()).forEach((country) => {
		if (country !== firstCountry) {
			const countryEntries = sortedAirportsByCountry.get(country) || [];
			const hasSelectedAirports = countryEntries.some((airport) => selectedOptionsProxy.value.includes(airport.value));
			toggleCountry(country, hasSelectedAirports);
		}
	});
};

onMounted(() => {
	manageCountryListOpenCloseState();
});

watch(selectedOptions, () => {
	selectedOptionsProxy.value = selectedOptions.value;
	manageCountryListOpenCloseState();
});
</script>

<style lang="scss" scoped>
:deep() {
	.search-modal {
		&__main {
			padding-top: 0;
			padding-bottom: 0;
		}

		&__button-row {
			box-shadow: 0.3rem -0.4rem 1.4rem 0 #6663;
		}
	}
}

#airport-container {
	overflow: auto;
	height: 100%;
}

.airport-filter {
	display: flex;
	flex-direction: column;

	&__selected-options {
		display: flex;
		align-items: center;
		gap: 1.6em;
		row-gap: 0.8em;
		overflow: auto;
		padding: 0.8rem 1.6rem;
		box-shadow: 0.3rem 0.4rem 1.4rem 0 #6663;

		@media (min-width: $breakpoint-extralarge) {
			flex-wrap: wrap;
			overflow: unset;
			padding: 1.6rem;
		}

		&--thin {
			font-weight: $font-weight-regular;
		}
	}

	:deep() {
		.dropdown {
			&__container:not(:has(.airport-filter__not-found)) {
				margin-top: 0;
				min-height: 33rem;
			}

			&__box {
				width: 42rem;
				left: -9.75rem;
			}

			&__inner {
				border-radius: $border-radius-large;
			}

			&__footer {
				justify-content: space-between;
				padding: 1.6rem 2.4rem;

				button.dropdown__cancel {
					background: none;
					color: $color-primary;

					&.is-disabled {
						color: $color-dark-gray;
					}
				}

				box-shadow: 0.3rem -0.4rem 1.4rem 0 #6663;
			}
		}

		.chip-base {
			border-color: $chip-base-delete-icon;
			color: $chip-base-delete-icon;

			&__label {
				white-space: nowrap;

				@media (min-width: $breakpoint-extralarge) {
					white-space: break-spaces;
				}
			}
		}
	}

	&-country {
		&-container {
			height: 100%;
			overflow: auto;
			margin-top: 1.6rem;

			@media (min-width: $breakpoint-extralarge) {
				height: 45rem !important;
			}

			.filter-active > div {
				height: auto !important;
			}

			&.has-selection {
				height: calc(100% - 7rem);
			}
		}

		&:not(:last-child, .filter-active) {
			border-bottom: 0.1rem solid $color-border;
		}

		&-name {
			margin: 1.6rem 2.4rem;
			font-size: $font-small-1;
			display: flex;
			cursor: pointer;
			justify-content: space-between;
			align-items: center;

			@media (min-width: $breakpoint-extralarge) {
				margin: 1.6rem;
			}

			span {
				font-weight: $font-weight-bold;
			}

			&__icon {
				color: $color-primary-darker;
				transition: transform 0.3s;
			}
		}
	}

	.airport-filter-country {
		&:nth-child(1) {
			.airport-filter-country-name {
				margin-top: 0;
			}
		}
	}

	:deep(.search-modal__main) {
		padding-inline: 0;
		display: flex;
		flex-direction: column;
		margin-top: 0;

		@media (min-width: $breakpoint-extralarge) {
			margin-top: unset;
		}
	}

	:deep(.dropdown__field) {
		padding-left: 1.6rem;
	}

	:deep(.form-field__icon) {
		flex-shrink: 0;
	}

	:deep(.dropdown-filter-type__mobile-form-field) {
		margin: 0;
	}

	&__not-found {
		font-size: $font-small-1;
		padding: 1.6rem 5.6rem;
	}

	&__checkbox {
		margin: 1.6rem 2.4rem;
		display: block;

		@media (min-width: $breakpoint-extralarge) {
			margin: 1.6rem;
		}

		&.first-item {
			margin-top: 0;
		}
	}

	&__checkbox-label {
		padding: 0;
		margin: 0;
		color: $color-text;
		font-size: $font-small-1;
		line-height: 2.179rem;

		&--strong {
			font-weight: $font-weight-bold;
		}
	}
}
</style>
