import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hotel-map" }
const _hoisted_2 = {
  key: 0,
  class: "hotel-map__location",
  "aria-label": "Hotel location"
}
const _hoisted_3 = { class: "hotel-map__location-text" }

import MapImage from './internals/MapImage.vue';

interface Props {
	location?: string;
	gaAttributes?: Record<string, string | number>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelMap',
  props: {
    location: {},
    gaAttributes: {}
  },
  emits: ['clickHotelMap'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;


const openFullScreenMap = () => {
	emit('clickHotelMap');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("button", _mergeProps({
      class: "hotel-map__container",
      "aria-label": "Open full-screen map"
    }, _ctx.gaAttributes, { onClick: openFullScreenMap }), [
      _createVNode(MapImage, {
        class: "hotel-map__location-map rpb_lazy",
        alt: "map"
      }),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "hotel-map__button-text" }, "Zur Karte", -1 /* HOISTED */))
    ], 16 /* FULL_PROPS */),
    (_ctx.location)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa-solid fa-location-dot hotel-map__icon" }, null, -1 /* HOISTED */)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.location), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})