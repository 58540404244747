<template>
	<div
		:class="{ 'has-error': hasError }"
		class="dropdown-filter-type"
	>
		<DropdownField
			v-if="isViewport"
			ref="desktopField"
			class="dropdown-filter-type__desktop"
			:model-value="fieldValue"
			:label="title"
			:placeholder="actualPlaceholder"
			:icon
			:is-box-visible="dropdownVisible"
			:allow-clear
			:show-toggle-icon
			:show-footer="showButtons"
			:button-wording="applyButtonText ?? getLocaleString('close')"
			:disabled="disabled"
			:readonly="!searchable"
			:with-clear-button="withClearButton"
			:with-cancel-button="withCancelButton"
			:button-disabled="buttonDisabled"
			:searchable
			:button-cancel="withCancelButton ? cancelButtonText : ''"
			:prevent-mousedown="false"
			@update:model-value="handleUpdate"
			@DropdownField:Ok="handleOk"
			@DropdownField:OutsideClick="handleOutsideClick"
			@DropdownField:open="handleUpdate"
			@DropdownField:Toggle="handleToggle"
			@DropdownField:Clear="handleClear"
			@key:enter="emit('DropdownFilterType:KeyEnter')"
		>
			<slot
				:close="close"
				:open="openModal"
			></slot>
		</DropdownField>
		<div
			v-else
			class="dropdown-filter-type__mobile"
		>
			<div
				v-if="withMenuHeader"
				class="dropdown-filter-type__mobile-header"
			>
				{{ title }}
			</div>
			<FormField
				ref="formField"
				class="dropdown-filter-type__mobile-form-field"
				:selected="selectedValue"
				:show-modal="true"
				:label="title"
				:icon
				:is-box-visible="dropdownVisible"
				:allow-clear
				:custom-clear-icon="customClearIcon"
				:placeholder
				:show-toggle-icon
				:disabled="disabled"
				@click="openModal"
				@FormField:Clear="handleClear"
				@key:enter="emit('DropdownFilterType:KeyEnter')"
			></FormField>
			<SearchModal
				ref="modal"
				v-model="searchTerm"
				class="dropdown-filter-type__mobile-modal"
				:header="modalHeader"
				:cancel-button="showButtons ? (cancelButtonText ?? 'Abbrechen') : ''"
				:apply-button="showButtons ? (applyButtonText ?? 'Ok') : ''"
				:searchable="searchable"
				:with-clear-button="withClearButton"
				input-clearable
				:is-focused
				:button-disabled
				:reset-instead-of-cancel="resetInsteadOfCancel"
				:clear-on-open="false"
				@SearchModal:applyChanges="handleApply"
				@SearchModal:close="handleAbort"
				@SearchModal:clear="handleClear"
				@SearchModal:focusedIn="() => emit('DropdownFilterType:focusedIn')"
				@SearchModal:focusedOut="() => emit('DropdownFilterType:focusedOut')"
			>
				<slot
					:close="close"
					:open="openModal"
				></slot>
			</SearchModal>
		</div>
	</div>
</template>
<script setup lang="ts">
import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { getLocaleString } from '@utils/environmentUtils';
import { debounce } from '@utils/utils';
import { useBreakpointStore } from 'src/store/breakpointsStore';

type Props = {
	title: string;
	modalTitle?: string;
	modalHeader?: string;
	icon: string;
	showButtons?: boolean;
	placeholder?: string;
	selectedValue?: string;
	disabled?: boolean;
	customClearIcon?: string;
	resetInsteadOfCancel?: boolean;
	buttonDisabled?: boolean;
	searchable?: boolean;
	emitOnOk?: boolean;
	withClearButton?: boolean;
	withCancelButton?: boolean;
	applyButtonText?: string;
	cancelButtonText?: string;
	searchTerm?: string;
	hasError?: boolean;
	withMenuHeader?: boolean;
	showToggleIcon?: boolean;
	isFocused?: boolean;
	allowClear?: boolean;
	dropdownVisible?: boolean;
	isCustomViewPort?: 'isDesktop' | 'isMobileLarge' | 'isMinTablet' | 'isMinTabletLandscape';
};

const props = withDefaults(defineProps<Props>(), {
	showButtons: false,
	hasError: false,
	placeholder: 'Beliebig',
	modalTitle: undefined,
	modalHeader: undefined,
	selectedValue: undefined,
	applyButtonText: undefined,
	cancelButtonText: undefined,
	emitOnOk: false,
	withClearButton: false,
	searchTerm: undefined,
	withMenuHeader: true,
	showToggleIcon: true,
	allowClear: false,
	dropdownVisible: true,
	isCustomViewPort: 'isDesktop',
	customClearIcon: 'close',
});

const emit = defineEmits([
	'DropdownFilterType:KeyEnter',
	'DropdownFilterType:focusedIn',
	'DropdownFilterType:focusedOut',
	'DropdownFilterType:apply',
	'DropdownFilterType:abort',
	'DropdownFilterType:filter',
	'DropdownFilterType:clear',
	'update:searchTerm',
]);

const isViewport = computed(() => useBreakpointStore()[props.isCustomViewPort]);
const desktopField = ref<InstanceType<typeof DropdownField>>();

const searchTerm = computed({
	get() {
		return props.searchTerm;
	},
	set(value: string) {
		emit('update:searchTerm', value);
	},
});
const fieldValue = computed(() => {
	if (!props.searchable) {
		return props.selectedValue;
	}
	return dropdownOpen.value ? searchTerm.value : props.selectedValue;
});

const onInput = (): void => {
	searchTerm.value = modal.value?.input?.value ?? '';
};
onMounted((): void => {
	modal.value?.input?.addEventListener('input', debounce(onInput, 400));
});

onBeforeUnmount((): void => {
	modal.value?.input?.removeEventListener('input', onInput);
});

const modal = ref<InstanceType<typeof SearchModal>>();
const formField = ref<InstanceType<typeof FormField>>();
const modalHeader = computed(() => props.modalHeader ?? `${props.modalTitle ?? props.title} auswählen`);
const openModal = () => {
	if (props.disabled) return;
	modal.value?.openModal();
};

const close = () => {
	desktopField.value?.closeDropdown();
	modal.value?.handleClose();
};

const dropdownOpen = ref(false);
const handleToggle = (isOpen: boolean) => {
	dropdownOpen.value = isOpen;
};
const actualPlaceholder = computed(() => {
	if (!props.searchable) {
		return props.placeholder;
	}
	return dropdownOpen.value ? '' : props.placeholder;
});

const handleUpdate = (value: string = searchTerm.value ?? '') => {
	if (!props.searchable) {
		return;
	}
	searchTerm.value = value;
};

const handleOk = (e) => {
	if (props.emitOnOk) {
		emit('DropdownFilterType:apply', e);
	}
	close();
};

const handleOutsideClick = (e) => {
	emit('DropdownFilterType:apply', e);
	close();
};

const handleApply = (e) => {
	emit('DropdownFilterType:apply', e);
};
const handleAbort = (e) => {
	emit('DropdownFilterType:abort', e);
};
const handleClear = () => {
	desktopField.value?.field?.input?.click();
	formField.value?.input?.click();
	emit('DropdownFilterType:clear');
};

defineExpose({
	close,
	open: openModal,
	modal,
	formField,
	desktopField,
});
</script>
<style lang="scss" scoped>
.has-error {
	:deep() {
		.form-field__label-text {
			color: $color-warning-2;
		}

		.dropdown > .form-field {
			border-color: $color-warning-2;
		}

		.dropdown__inner {
			overflow: visible !important;
		}
	}
}

:deep() {
	.search-modal__main {
		margin-top: 0 !important;
		padding-top: 0 !important;
	}

	.dropdown-filter-type__mobile-form-field {
		margin: 0;
	}

	.search-modal__main,
	.list__body {
		margin-top: 0 !important;
	}
}

.dropdown-filter-type {
	&__mobile-header {
		background-color: $header-bar-bg;
		color: $color-filter-type-header-text;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		padding: 1.2rem 2.4rem;
		box-shadow: $box-shadow;
	}

	&__mobile-form-field {
		width: auto;
	}

	:deep(.search-modal__button) {
		font-weight: $font-weight-bold;
	}

	:deep(.search-modal__button--cancel) {
		height: auto;
	}

	:deep(.search-modal__button--apply) {
		height: auto;
		background-color: $color-primary;
	}

	:deep() {
		.has-error {
			.dropdown.is-open {
				.dropdown__box {
					max-height: 55rem !important;
				}
			}
		}

		&:not(.has-error) {
			.dropdown.is-open {
				.dropdown__box {
					max-height: 50rem !important;
				}
			}
		}
	}

	:deep(.dropdown__box) {
		transform: none;
		left: 0;
	}

	:deep(.dropdown__container) {
		@include customScrollbar($color-primary);

		& {
			overflow: hidden auto;
		}
	}

	:deep(.search-modal__main) {
		@include customScrollbar($color-primary);

		& {
			overflow-x: hidden;
		}
	}
}
</style>
