export const rangeArray = (min: number, max: number): number[] => {
	return [...Array(max + 1 - min)].map((_: number, index: number) => min + index);
};

export const arraySum = (arr: number[]): number => arr.reduce((a, b) => a + b, 0);

/** *
 * Splits an array in n parts. The integer remainder is distributed evenly among the first n parts.
 * */
export function chunkArray<T>(arr: T[], n: number): T[][] {
	const chunkLength = Math.floor(arr.length / n);
	const remainder = arr.length % n;
	const chunks: T[][] = [];
	let start = 0;

	for (let i = 0; i < n; i++) {
		const extra = i < remainder ? 1 : 0;
		const end = start + chunkLength + extra;
		chunks.push(arr.slice(start, end));
		start = end;
	}
	return chunks;
}

/**
 * Normalizes a comma-separated string by ensuring a space after each comma.
 * @param list - The input string to normalize.
 * @returns The normalized string.
 */
export const normalizeHotelAttributes = (list: string): string => {
    return list.replaceAll(', ', ',').split(',').join(', ');
};
/** *
 * Splits an array in n parts. The integer remainder is distributed evenly among the first n parts.
 *
 * The parts are created horizontally, i.e. the first element of the array is added to the first part, the second element to the second part, etc.
 *
 * Example: [1, 2, 3, 4, 5, 6, 7, 8, 9] -> [[1, 4, 7], [2, 5, 8], [3, 6, 9]]
 * */
export function chunkArrayHorizontal<T>(arr: T[], n: number): T[][] {
	const arrays: T[][] = Array.from({ length: n }, () => []);
	for (let i = 0; i < arrays.length; i++) {
		for (let j = i; j < arr.length; j += arrays.length) {
			arrays[i]?.push(arr[j]);
		}
	}
	return arrays;
}
