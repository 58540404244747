import type { SelectContent, SelectContentType } from '@/js/modules/analytics/Ga4';

type GaEventData<Event> = {
	'data-ga-event': Event;
	'data-ga-content_type': SelectContentType;
	'data-ga-content_id': string;
	'data-ga-index'?: number; // Assuming index is a number
};

/**
 * A function that returns an object with the attributes for the select_content event.
 * @param param0  The object with the cId, cType, and index properties.
 * @returns
 */
export function gaSelectContentAttr({ cId, cType = 'ux_hotelpage_click', index }: SelectContent) {
	const attributes: GaEventData<'select_content'> = {
		'data-ga-event': 'select_content',
		'data-ga-content_type': cType,
		'data-ga-content_id': cId,
	};
	if (index !== undefined) {
		Object.assign(attributes, { 'data-ga-index': index });
	}
	return attributes;
}
