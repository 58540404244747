<template>
	<DropdownFilterType
		ref="dropdown"
		v-model:search-term="filterExpression"
		class="operator-filter"
		:class="{ 'operator-filter--disabled': disabled }"
		:disabled="disabled"
		:read-only="false"
		title="Veranstalter"
		icon="sphere"
		:selected-value="selectedOptionsConcat"
		apply-button="OK"
		abort-button="Abbrechen"
		:placeholder="disabled ? 'Keine Treffer' : 'Beliebig'"
		:with-clear-button="checkboxModel.length > 0"
		:with-menu-header="false"
		modal-header="Veranstalter"
		:show-toggle-icon="false"
		show-buttons
		searchable
		@DropdownFilterType:apply="onApply"
		@DropdownFilterType:abort="onAbort"
		@dropdown-filter-type:clear="clearSelectedOptions"
	>
		<template #default>
			<p
				v-if="notFoundMessage"
				class="operator-filter__not-found"
			>
				{{ notFoundMessage }}
			</p>
			<template v-else>
				<Checkbox
					v-for="option in filteredItems"
					:key="option.Code"
					v-model="checkboxModel"
					class="operator-filter__checkbox-item"
					:label="option.Name"
					:custom-value="option.Code"
					:disabled="props.disabled"
					@update:model-value="resetFilter"
				/>
			</template>
		</template>
	</DropdownFilterType>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { useStore } from '@/components/common/store';
import { useFilterArray } from '../../composables/filterArray';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
	disabled: false,
});

const store = useStore();
const {isDesktop} = storeToRefs(useBreakpointStore());
const selectedOptions = computed(() => (store.state.searchMask.operatorTypes as string[]) ?? []);
const proxy = ref<string[]>(selectedOptions.value ?? []);
const checkboxOptionList = computed(() => store.state.types.operator ?? []);
const { filteredItems, notFoundMessage, filterExpression, resetFilter } = useFilterArray(checkboxOptionList, ['Name']);
const selectedOptionsConcat = computed(() => {
	if (props.disabled) return '';
	if (!checkboxOptionList.value.length === 0) return '';
	return checkboxOptionList.value
		.filter((option) => selectedOptions.value.includes(option.Code))
		.map((option) => option.Name)
		.join(', ');
});

const checkboxModel = computed({
	get() {
		return isDesktop.value ? selectedOptions.value : proxy.value;
	},
	set(value: string[]) {
		if (isDesktop.value) {
			store.commit('searchMask/updateFormData', {
				operatorTypes: value,
			});
		} else {
			proxy.value = value;
		}
	},
});
const clearSelectedOptions = () => {
	checkboxModel.value = [];
};
watch(selectedOptions, (newVal) => {
	if (!isDesktop.value) return;
	if (proxy.value.length === newVal.length && newVal.every((value) => proxy.value.includes(value))) return;
	proxy.value = newVal;
});

function onApply() {
	if (!isDesktop.value) {
		store.commit('searchMask/updateFormData', {
			operatorTypes: proxy.value,
		});
	}
}
function onAbort() {
	if (!isDesktop.value) {
		proxy.value = selectedOptions.value;
	}
}
</script>
<style lang="scss" scoped>
.operator-filter {
	:deep(.search-modal__main) {
		padding-inline: 0;
		padding-top: 1.6rem !important;
	}

	:deep(.form-field) {
		.form-field__icon {
			width: 3.875rem;
			height: 3rem;
			align-self: flex-end;
			margin-bottom: 0.75rem;
		}
	}

	&__not-found {
		font-size: $font-small-1;
		padding-right: 1.6rem;
		padding-left: 5.6rem;
	}

	:deep(.checkbox__icon) {
		width: 1.5rem;
		height: 1.5rem;
	}

	&__checkbox-item {
		font-size: $font-small-1;
		display: block;
		margin-bottom: 1.6rem;
		margin-left: 1.6rem;
	}

	&--disabled {
		:deep(.form-field__icon),
		:deep(.form-field__chevron-icon) {
			fill: $color-state-disabled;
		}

		:deep(.form-field) {
			cursor: default;
			border-color: $color-state-disabled;
		}

		:deep(.form-field__label-text) {
			color: $color-state-disabled;
		}

		:deep(.form-field__input::placeholder) {
			color: $color-state-disabled !important;
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.operator-filter {
		max-width: 32rem;
	}
}
</style>
