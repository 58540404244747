import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { nextTick, ref, useTemplateRef } from 'vue';
import BaseModal from '@lmt-rpb/BaseModal/BaseModal.vue';

interface Props {
	title: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OfferDetailsModal',
  props: {
    title: { default: 'Title' }
  },
  emits: ['OfferDetailsModal:closeModal'],
  setup(__props: any, { expose: __expose, emit: __emit }) {



const emit = __emit;
const offerDetailsModalRef = useTemplateRef<InstanceType<typeof BaseModal>>('offerDetailsModalRef');
const abortController = ref<AbortController | null>(null);

const handleLinkClick = (event: MouseEvent) => {
	event.preventDefault();
	const href =
		(event.target as HTMLAnchorElement).getAttribute('href') || (event.target as HTMLAnchorElement).parentElement?.getAttribute('href');

	if (href) {
		const hash = href.slice(1);

		if (hash) {
			// Look inside the modal's main content for the target anchor
			const modalContent = offerDetailsModalRef.value?.$el?.nextElementSibling?.querySelector('.base-modal__main');
			const targetElement = modalContent?.querySelector(`[name="${hash}"]`);

			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	}
};

const setupAnchorLinks = () => {
	if (offerDetailsModalRef.value) {
		abortController.value = new AbortController();
		const signal = abortController.value.signal;

		// Find all anchor links inside the modal
		const anchorLinks = (offerDetailsModalRef.value.$el?.nextElementSibling as HTMLElement)?.querySelectorAll<HTMLAnchorElement>(
			'.base-modal__main a[href^="#"]'
		);

		anchorLinks?.forEach((anchor) => {
			anchor.addEventListener('click', handleLinkClick, { signal });
		});
	}
};

const destroyAnchorLinks = () => {
	abortController.value?.abort();
};

const open = (): void => {
	offerDetailsModalRef.value?.openModal();

	nextTick(() => {
		setupAnchorLinks();
	});
};

const handleCloseEvent = (): void => {
	destroyAnchorLinks();
	emit('OfferDetailsModal:closeModal');
};

__expose({
	open,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BaseModal, {
    ref_key: "offerDetailsModalRef",
    ref: offerDetailsModalRef,
    "header-title": _ctx.title,
    onClose: handleCloseEvent
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["header-title"]))
}
}

})