<template>
	<BaseModal
		ref="offerDetailsModalRef"
		:header-title="title"
		@close="handleCloseEvent"
	>
		<template #default>
			<slot />
		</template>
	</BaseModal>
</template>

<script lang="ts" setup>
import { nextTick, ref, useTemplateRef } from 'vue';
import BaseModal from '@lmt-rpb/BaseModal/BaseModal.vue';

interface Props {
	title: string;
}

withDefaults(defineProps<Props>(), {
	title: 'Title',
});

const emit = defineEmits(['OfferDetailsModal:closeModal']);
const offerDetailsModalRef = useTemplateRef<InstanceType<typeof BaseModal>>('offerDetailsModalRef');
const abortController = ref<AbortController | null>(null);

const handleLinkClick = (event: MouseEvent) => {
	event.preventDefault();
	const href =
		(event.target as HTMLAnchorElement).getAttribute('href') || (event.target as HTMLAnchorElement).parentElement?.getAttribute('href');

	if (href) {
		const hash = href.slice(1);

		if (hash) {
			// Look inside the modal's main content for the target anchor
			const modalContent = offerDetailsModalRef.value?.$el?.nextElementSibling?.querySelector('.base-modal__main');
			const targetElement = modalContent?.querySelector(`[name="${hash}"]`);

			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	}
};

const setupAnchorLinks = () => {
	if (offerDetailsModalRef.value) {
		abortController.value = new AbortController();
		const signal = abortController.value.signal;

		// Find all anchor links inside the modal
		const anchorLinks = (offerDetailsModalRef.value.$el?.nextElementSibling as HTMLElement)?.querySelectorAll<HTMLAnchorElement>(
			'.base-modal__main a[href^="#"]'
		);

		anchorLinks?.forEach((anchor) => {
			anchor.addEventListener('click', handleLinkClick, { signal });
		});
	}
};

const destroyAnchorLinks = () => {
	abortController.value?.abort();
};

const open = (): void => {
	offerDetailsModalRef.value?.openModal();

	nextTick(() => {
		setupAnchorLinks();
	});
};

const handleCloseEvent = (): void => {
	destroyAnchorLinks();
	emit('OfferDetailsModal:closeModal');
};

defineExpose({
	open,
});
</script>
