import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "dropdown-filter-type__mobile"
}
const _hoisted_2 = {
  key: 0,
  class: "dropdown-filter-type__mobile-header"
}

import DropdownField from '@lmt-rpb/DropdownField/DropdownField.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import SearchModal from '@lmt-rpb/SearchModal/SearchModal.vue';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { getLocaleString } from '@utils/environmentUtils';
import { debounce } from '@utils/utils';
import { useBreakpointStore } from 'src/store/breakpointsStore';

type Props = {
	title: string;
	modalTitle?: string;
	modalHeader?: string;
	icon: string;
	showButtons?: boolean;
	placeholder?: string;
	selectedValue?: string;
	disabled?: boolean;
	customClearIcon?: string;
	resetInsteadOfCancel?: boolean;
	buttonDisabled?: boolean;
	searchable?: boolean;
	emitOnOk?: boolean;
	withClearButton?: boolean;
	withCancelButton?: boolean;
	applyButtonText?: string;
	cancelButtonText?: string;
	searchTerm?: string;
	hasError?: boolean;
	withMenuHeader?: boolean;
	showToggleIcon?: boolean;
	isFocused?: boolean;
	allowClear?: boolean;
	dropdownVisible?: boolean;
	isCustomViewPort?: 'isDesktop' | 'isMobileLarge' | 'isMinTablet' | 'isMinTabletLandscape';
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownFilterType',
  props: {
    title: {},
    modalTitle: { default: undefined },
    modalHeader: { default: undefined },
    icon: {},
    showButtons: { type: Boolean, default: false },
    placeholder: { default: 'Beliebig' },
    selectedValue: { default: undefined },
    disabled: { type: Boolean },
    customClearIcon: { default: 'close' },
    resetInsteadOfCancel: { type: Boolean },
    buttonDisabled: { type: Boolean },
    searchable: { type: Boolean },
    emitOnOk: { type: Boolean, default: false },
    withClearButton: { type: Boolean, default: false },
    withCancelButton: { type: Boolean },
    applyButtonText: { default: undefined },
    cancelButtonText: { default: undefined },
    searchTerm: { default: undefined },
    hasError: { type: Boolean, default: false },
    withMenuHeader: { type: Boolean, default: true },
    showToggleIcon: { type: Boolean, default: true },
    isFocused: { type: Boolean },
    allowClear: { type: Boolean, default: false },
    dropdownVisible: { type: Boolean, default: true },
    isCustomViewPort: { default: 'isDesktop' }
  },
  emits: [
	'DropdownFilterType:KeyEnter',
	'DropdownFilterType:focusedIn',
	'DropdownFilterType:focusedOut',
	'DropdownFilterType:apply',
	'DropdownFilterType:abort',
	'DropdownFilterType:filter',
	'DropdownFilterType:clear',
	'update:searchTerm',
],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const isViewport = computed(() => useBreakpointStore()[props.isCustomViewPort]);
const desktopField = ref<InstanceType<typeof DropdownField>>();

const searchTerm = computed({
	get() {
		return props.searchTerm;
	},
	set(value: string) {
		emit('update:searchTerm', value);
	},
});
const fieldValue = computed(() => {
	if (!props.searchable) {
		return props.selectedValue;
	}
	return dropdownOpen.value ? searchTerm.value : props.selectedValue;
});

const onInput = (): void => {
	searchTerm.value = modal.value?.input?.value ?? '';
};
onMounted((): void => {
	modal.value?.input?.addEventListener('input', debounce(onInput, 400));
});

onBeforeUnmount((): void => {
	modal.value?.input?.removeEventListener('input', onInput);
});

const modal = ref<InstanceType<typeof SearchModal>>();
const formField = ref<InstanceType<typeof FormField>>();
const modalHeader = computed(() => props.modalHeader ?? `${props.modalTitle ?? props.title} auswählen`);
const openModal = () => {
	if (props.disabled) return;
	modal.value?.openModal();
};

const close = () => {
	desktopField.value?.closeDropdown();
	modal.value?.handleClose();
};

const dropdownOpen = ref(false);
const handleToggle = (isOpen: boolean) => {
	dropdownOpen.value = isOpen;
};
const actualPlaceholder = computed(() => {
	if (!props.searchable) {
		return props.placeholder;
	}
	return dropdownOpen.value ? '' : props.placeholder;
});

const handleUpdate = (value: string = searchTerm.value ?? '') => {
	if (!props.searchable) {
		return;
	}
	searchTerm.value = value;
};

const handleOk = (e) => {
	if (props.emitOnOk) {
		emit('DropdownFilterType:apply', e);
	}
	close();
};

const handleOutsideClick = (e) => {
	emit('DropdownFilterType:apply', e);
	close();
};

const handleApply = (e) => {
	emit('DropdownFilterType:apply', e);
};
const handleAbort = (e) => {
	emit('DropdownFilterType:abort', e);
};
const handleClear = () => {
	desktopField.value?.field?.input?.click();
	formField.value?.input?.click();
	emit('DropdownFilterType:clear');
};

__expose({
	close,
	open: openModal,
	modal,
	formField,
	desktopField,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'has-error': _ctx.hasError }, "dropdown-filter-type"])
  }, [
    (isViewport.value)
      ? (_openBlock(), _createBlock(DropdownField, {
          key: 0,
          ref_key: "desktopField",
          ref: desktopField,
          class: "dropdown-filter-type__desktop",
          "model-value": fieldValue.value,
          label: _ctx.title,
          placeholder: actualPlaceholder.value,
          icon: _ctx.icon,
          "is-box-visible": _ctx.dropdownVisible,
          "allow-clear": _ctx.allowClear,
          "show-toggle-icon": _ctx.showToggleIcon,
          "show-footer": _ctx.showButtons,
          "button-wording": _ctx.applyButtonText ?? _unref(getLocaleString)('close'),
          disabled: _ctx.disabled,
          readonly: !_ctx.searchable,
          "with-clear-button": _ctx.withClearButton,
          "with-cancel-button": _ctx.withCancelButton,
          "button-disabled": _ctx.buttonDisabled,
          searchable: _ctx.searchable,
          "button-cancel": _ctx.withCancelButton ? _ctx.cancelButtonText : '',
          "prevent-mousedown": false,
          "onUpdate:modelValue": handleUpdate,
          "onDropdownField:Ok": handleOk,
          "onDropdownField:OutsideClick": handleOutsideClick,
          "onDropdownField:open": handleUpdate,
          "onDropdownField:Toggle": handleToggle,
          "onDropdownField:Clear": handleClear,
          "onKey:enter": _cache[0] || (_cache[0] = ($event: any) => (emit('DropdownFilterType:KeyEnter')))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {
              close: close,
              open: openModal
            })
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["model-value", "label", "placeholder", "icon", "is-box-visible", "allow-clear", "show-toggle-icon", "show-footer", "button-wording", "disabled", "readonly", "with-clear-button", "with-cancel-button", "button-disabled", "searchable", "button-cancel"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.withMenuHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createVNode(FormField, {
            ref_key: "formField",
            ref: formField,
            class: "dropdown-filter-type__mobile-form-field",
            selected: _ctx.selectedValue,
            "show-modal": true,
            label: _ctx.title,
            icon: _ctx.icon,
            "is-box-visible": _ctx.dropdownVisible,
            "allow-clear": _ctx.allowClear,
            "custom-clear-icon": _ctx.customClearIcon,
            placeholder: _ctx.placeholder,
            "show-toggle-icon": _ctx.showToggleIcon,
            disabled: _ctx.disabled,
            onClick: openModal,
            "onFormField:Clear": handleClear,
            "onKey:enter": _cache[1] || (_cache[1] = ($event: any) => (emit('DropdownFilterType:KeyEnter')))
          }, null, 8 /* PROPS */, ["selected", "label", "icon", "is-box-visible", "allow-clear", "custom-clear-icon", "placeholder", "show-toggle-icon", "disabled"]),
          _createVNode(SearchModal, {
            ref_key: "modal",
            ref: modal,
            modelValue: searchTerm.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchTerm).value = $event)),
            class: "dropdown-filter-type__mobile-modal",
            header: modalHeader.value,
            "cancel-button": _ctx.showButtons ? (_ctx.cancelButtonText ?? 'Abbrechen') : '',
            "apply-button": _ctx.showButtons ? (_ctx.applyButtonText ?? 'Ok') : '',
            searchable: _ctx.searchable,
            "with-clear-button": _ctx.withClearButton,
            "input-clearable": "",
            "is-focused": _ctx.isFocused,
            "button-disabled": _ctx.buttonDisabled,
            "reset-instead-of-cancel": _ctx.resetInsteadOfCancel,
            "clear-on-open": false,
            "onSearchModal:applyChanges": handleApply,
            "onSearchModal:close": handleAbort,
            "onSearchModal:clear": handleClear,
            "onSearchModal:focusedIn": _cache[3] || (_cache[3] = () => emit('DropdownFilterType:focusedIn')),
            "onSearchModal:focusedOut": _cache[4] || (_cache[4] = () => emit('DropdownFilterType:focusedOut'))
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {
                close: close,
                open: openModal
              })
            ]),
            _: 3 /* FORWARDED */
          }, 8 /* PROPS */, ["modelValue", "header", "cancel-button", "apply-button", "searchable", "with-clear-button", "is-focused", "button-disabled", "reset-instead-of-cancel"])
        ]))
  ], 2 /* CLASS */))
}
}

})