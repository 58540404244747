import type { SimplePrice } from '@/interfaces/checkout/offer-data';
import type { InsuranceOffer } from '@/interfaces/checkout/insurance-data';
import { determineClient, getDefaultCurrency, getLocaleString } from '@utils/environmentUtils';
import type { ClientCurrency } from '@global-js/constants';
import { CURRENCIES, NBSP } from '@global-js/constants';
import { useStore } from '@/components/common/store';
import type { PriceType, TravelerAmount, Pricing } from '@/interfaces/common';

export const getFormatPriceEur = (price?: SimplePrice, showCents = false) => {
	let result = '';
	if (price) {
		const amount = price.Amount;
		const currency = price.Currency;
		result = formatPrice(amount, currency, showCents);
	}
	return result;
};
export const getInsurancePrice = (insurance?: Partial<InsuranceOffer>, isCH = false, extraSpan = false) => {
	if (!insurance || !insurance.Price) {
		return '';
	}
	const euroPrice = formatPrice(insurance.Price, 'EUR', true);
	if (!isCH) {
		return euroPrice;
	}
	const converted = insurance.PriceInformation?.Converted;
	if (!converted) {
		return '';
	}
	const convertedPrice = formatPrice(converted.Amount, converted.Currency, true);
	const suffix = extraSpan ? `<span class="insurance-price__currency">(${euroPrice})</span>` : `(${euroPrice})`;

	return `${convertedPrice} ${suffix}`;
};

export const getFormatPriceConverted = (price?: SimplePrice, showCents = false) => {
	let result = '';
	if (price) {
		const convertedPrice = price.Converted;
		const amount = convertedPrice?.Amount || price.Amount;
		const currency = convertedPrice?.Currency || price.Currency;
		result = formatPrice(amount, currency, showCents);
	}
	return result;
};
/**
 * Formats a price based on the current domain.
 * Output will be "€ 1234"
 * @param num number that will be formatted to a price e.g. 1234.5
 * @param currency 'EUR' or 'CHF' => result will have the right currency prefix
 * @param showCents if true ouput will have 2 numbers after decimal point eg. € 1234.50
 */
export const formatPrice = (num: number | null, currency: string = getLocaleString('currency'), showCents = false): string => {
	if (num === null) return '';
	let minimumFractionDigits = 0;
	const maximumFractionDigits = showCents ? 2 : 0;
	if (maximumFractionDigits === 2 && (num * 10) % 10) {
		//	don't show prices like € 10,9
		minimumFractionDigits = 2;
	}
	const intl = new Intl.NumberFormat('de-DE', {
		currency,
		style: 'currency',
		minimumFractionDigits,
		maximumFractionDigits,
	});

	return intl.format(num);
};

/**
 * Separates the price from the currency
 * @param price
 * @returns
 */
export const separatePrice = (price: string) => {
	const [amount, symbol] = price.split(NBSP);
	return { amount, symbol };
};

export const getPriceCorrectCurrency = (price?: SimplePrice, showCents = false) => {
	if (!price) return '';
	const convertedPrice = price.Converted;
	const amount = convertedPrice?.Amount || price.Amount;
	const currency = convertedPrice?.Currency || price.Currency;
	return formatPrice(amount, currency, showCents);
};

interface Verified {
	price?: number | null;
	currency?: string;
}

interface UnverifiedOffer {
	price?: number;
	currency?: string;
	travellerAmount: number;
}

const isUnVerified = (status: string) => status === 'CheckUnable' || status === 'Undefined';

export const getFinalPrice = (
	status: string,
	price: number | undefined | null,
	unverifiedPrice: number | undefined = 0,
	travellerAmount: number
) => {
	// If status 'CheckUnable' or 'Undefined'
	// we just have the total price of one person from the unverified offer
	return isUnVerified(status) ? unverifiedPrice * travellerAmount : (price as number);
};

export const getFinalCurrency = (status: string, currency: string | undefined, unverifiedCurrency: string | undefined) => {
	return (isUnVerified(status) ? unverifiedCurrency : currency) as string;
};

export const getFinalPriceWithCurrency = (status: string, verified: Verified, unverified: UnverifiedOffer) => {
	const priceResult = getFinalPrice(status, verified.price, unverified.price, unverified.travellerAmount);
	const currencyResult = getFinalCurrency(status, verified.currency, unverified.currency);
	return formatPrice(priceResult, currencyResult, true);
};

export const currencyCodes = {
	euro: 'EUR',
	switzerland: 'CHF',
};

interface PriceTypeWordingOptions {
	type: PriceType;
	perPersonPrice?: number;
	currency?: string;
	travelers?: {
		children?: TravelerAmount['Children'];
	};
}

const formatPerPersonPrice = (price: number | undefined | null, currency: string | undefined): string => {
	if (price === undefined || currency === undefined) return '';
	return `p.P. ${formatPrice(price, currency)}`;
};

const hasValidPriceInfo = (perPersonPrice?: number, currency?: string): boolean => {
	return perPersonPrice !== undefined && currency !== undefined;
};

const shouldShowPriceWording = (
	adult: number,
	childrenCount: number,
	hasChildren: boolean,
	type: PriceType,
	perPersonPrice?: number,
	currency?: string
): boolean => {
	// Single adult without children - no wording needed
	if (adult === 1 && childrenCount === 0) {
		return false;
	}

	// Show per person price if no children or it's a total price
	if ((!hasChildren || type === 'TotalPrice') && hasValidPriceInfo(perPersonPrice, currency)) {
		return true;
	}

	return true;
};

export const getPriceTypeWording = ({ type, perPersonPrice, currency, travelers }: PriceTypeWordingOptions): string => {
	const store = useStore();
	const storeChildren = store.state.searchMask.travelers.children;
	const storeAdult = store.state.searchMask.travelers.adult;

	const hasChildren = Boolean(travelers?.children?.length ?? storeChildren.length > 0);
	const childrenCount = storeChildren.length;

	if (!shouldShowPriceWording(storeAdult, childrenCount, hasChildren, type, perPersonPrice, currency)) {
		return '';
	}

	// If no children or total price, show per person price
	if ((!hasChildren || type === 'TotalPrice') && hasValidPriceInfo(perPersonPrice, currency)) {
		return formatPerPersonPrice(perPersonPrice, currency);
	}

	const typeMap: Record<PriceType, string> = {
		FullPayer: 'ggf. Kinderrabatt',
		AveragePerson: 'inkl. Kinderrabatt',
		TotalPrice: formatPerPersonPrice(perPersonPrice, currency),
	};

	return typeMap[type] ?? '';
};

/**
 * Gets the total price from a Pricing object, using converted amount if available
 * @param pricing The pricing object containing Total price information
 * @returns The total price amount
 */
export const getTotalPrice = (pricing: { Total?: { Converted?: number; Amount: number } }): number => {
	return pricing.Total?.Converted ?? pricing.Total?.Amount ?? 0;
};

/**
 *
 * Extracts price from a Pricing object, using the converted amount if available
 *
 * @param price a Pricing object to extract prices from
 * @param onlySingle param to control whether the Single or Total price is needed
 * @returns an object containing the amount and the currency
 */

export function getPricing(price: Pricing, onlySingle = false): { amount: number | null; currency: ClientCurrency } {
	const currency = getDefaultCurrency();
	const isChf = currency === 'CHF';
	const priceKey: keyof Omit<Pricing, 'Type'> = onlySingle ? 'Single' : 'Total';
	const amount: keyof Pricing[typeof priceKey] = isChf ? 'Converted' : 'Amount';
	return { amount: price[priceKey][amount], currency };
}
