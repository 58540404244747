import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "link-button__text" }

import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { computed } from 'vue';

export interface Props {
	as?: 'a' | 'button';
	label?: string;
	withIcon?: boolean;
	theme?: 'primary' | 'inherit';
}

export default /*@__PURE__*/_defineComponent({
  __name: 'LinkButton',
  props: {
    as: { default: 'button' },
    label: {},
    withIcon: { type: Boolean, default: true },
    theme: { default: 'primary' }
  },
  setup(__props: any) {



const defaults = computed(() => (__props.as === 'button' ? { type: 'button' } : {}));
const blockClassModifiers = computed(() => ({
	[`link-button--${__props.theme}`]: __props.theme,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.as), _mergeProps({
    class: ['link-button', blockClassModifiers.value]
  }, { ...defaults.value, ..._ctx.$attrs }), {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.label), 1 /* TEXT */)
        ])
      ]),
      (_ctx.withIcon)
        ? (_openBlock(), _createBlock(FaIcon, {
            key: 0,
            class: "link-button__icon",
            icon: ['fa-solid', 'fa-chevron-right']
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["class"]))
}
}

})