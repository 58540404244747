import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-filters" }
const _hoisted_2 = { class: "list-filters__content" }

import BoardTypeFilter from '@lmt-rpb/BoardTypeFilter/BoardTypeFilter.vue';
import HotelTransferFilter from '@lmt-rpb/HotelTransferFilter/HotelTransferFilter.vue';
import RecommendationRateFilter from '@lmt-rpb/RecommendationRateFilter/RecommendationRateFilter.vue';
import PriceFilter from '@lmt-rpb/PriceFilter/PriceFilter.vue';
import HotelCategoryFilter from '@lmt-rpb/HotelCategoryFilter/HotelCategoryFilter.vue';
import SeaViewFilter from '@lmt-rpb/SeaViewFilter/SeaViewFilter.vue';
import CustomerRatingFilter from '@lmt-rpb/CustomerRatingFilter/CustomerRatingFilter.vue';
import DestinationFilter from '@lmt-rpb/DestinationFilter/DestinationFilter.vue';
import DirectFlightFilter from '@lmt-rpb/DirectFlightFilter/DirectFlightFilter.vue';
import FlyingTimeFilter from '@lmt-rpb/FlyingTimeFilter/FlyingTimeFilter.vue';
import RoomTypeFilter from '@lmt-rpb/RoomTypeFilter/RoomTypeFilter.vue';
import MostPopularFilters from '@lmt-rpb/MostPopularFiltersNew/MostPopularFiltersNew.vue';
import OperatorFilter from '@lmt-rpb/OperatorFilter/OperatorFilter.vue';
import type { SearchFormDataType } from '@interfaces/search-form';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import FilterContainer from '@lmt-rpb/FilterContainer/FilterContainer.vue';
import { computed, onMounted, ref } from 'vue';
import FlexFilter from '@lmt-rpb/FlexFilter/FlexFilter.vue';
import DealsFilter from '@lmt-rpb/Organisms/DealsFilter/DealsFilter.vue';
import { useTranslations } from '@helper/composables/translations';
import { useStore } from '@/components/common/store';
import { isClientGermany } from '@/js/utils/environmentUtils';
import type { LocationType } from '@/interfaces/components/hotelListData';
import type { RoomTypeValue } from '@/js/data/room-types';

interface Props {
	destinationLabel?: string;
	listType: 'hotel' | 'region' | 'offer';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ListFilters',
  props: {
    destinationLabel: { default: '' },
    listType: {}
  },
  setup(__props: any) {

const props = __props;

const { translate } = useTranslations();

const dealsFilterTitle = computed(() => translate('filtersSection.dealsOnly.title'));
const directFlightChecked = ref(false);
const selectedRoomFilter = ref(false);
const selectedPopularFilter = ref(false);
const selectedRatingFilter = ref(false);
const updateDirectFlightChecked = (value: boolean) => {
	directFlightChecked.value = value;
};

const handleSelectedFilterUpdate = (filters: RoomTypeValue[]) => {
	selectedRoomFilter.value = filters.length > 0;
};

const handlePopularFilterUpdate = (value: boolean) => {
	selectedPopularFilter.value = value;
};

const handleRatingFilterUpdate = (value: boolean) => {
	selectedRatingFilter.value = value;
};
const store = useStore();
const formData = computed((): SearchFormDataType => store.state.searchMask);
const locationType = computed((): LocationType => store.state.config.locationType);

const showDestinationFilter = computed((): boolean => props.listType === 'hotel' && locationType.value !== 'CITY');
const destinationTypes = computed(() => store.state.types.destination);

const isHotelListPage = computed(() => props.listType === 'hotel');
const isOfferList = computed(() => props.listType === 'offer');

const isHotelOnly = computed((): boolean => formData.value.onlyHotel);

const operatorTypes = computed(() => store.state.types.operator);

const showSuns = isClientGermany();

const currencyType = computed(() => store.getters.getCurrencyType);

const tourOperatorLoading = computed((): boolean => store.state.tourOperatorLoading);

const destinationFilterLoading = computed((): boolean => store.state.destinationFilterLoading);

const setAccommodationType = (onlyHotel: boolean): void => {
	store.commit('searchMask/updateFormData', { onlyHotel });
};

onMounted(() => {
	if (isHotelOnly.value) {
		setAccommodationType(true);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "list-filters__header" }, "Suchergebnisse filtern", -1 /* HOISTED */)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (!isHotelOnly.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 0,
              title: "Flug",
              icon: ['fa-kit', 'fa-plane-direct'],
              "delete-button-string": "directFlight",
              "is-checked": directFlightChecked.value,
              "with-divider": false
            }, {
              default: _withCtx(() => [
                _createVNode(DirectFlightFilter, {
                  class: "list-filters__direct-flight",
                  "onUpdate:isChecked": updateDirectFlightChecked
                }),
                _createVNode(FlyingTimeFilter, { class: "list-filters__flight" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["is-checked"]))
          : _createCommentVNode("v-if", true),
        (!isHotelOnly.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 1,
              icon: ['fa-solid', 'fa-bus'],
              title: "Hoteltransfer"
            }, {
              default: _withCtx(() => [
                _createVNode(HotelTransferFilter)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, {
          title: "Verpflegung",
          icon: ['fa-solid', 'fa-utensils'],
          "with-divider": !isHotelOnly.value
        }, {
          default: _withCtx(() => [
            _createVNode(BoardTypeFilter, {
              "is-hotel-page": isOfferList.value,
              class: "list-filters__board-types"
            }, null, 8 /* PROPS */, ["is-hotel-page"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["with-divider"]),
        _createVNode(FilterContainer, {
          title: "Zimmertyp",
          icon: ['fa-solid', 'fa-bed'],
          "delete-value": "roomType",
          "delete-button-string": "roomtype",
          "is-checked": selectedRoomFilter.value
        }, {
          default: _withCtx(() => [
            _createVNode(RoomTypeFilter, {
              "is-hotel-page": isOfferList.value,
              class: "list-filters__room-type",
              "is-checked": selectedRoomFilter.value,
              "onUpdate:selectedFilters": handleSelectedFilterUpdate
            }, null, 8 /* PROPS */, ["is-hotel-page", "is-checked"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["is-checked"]),
        _createVNode(FilterContainer, null, {
          default: _withCtx(() => [
            _createVNode(SeaViewFilter)
          ]),
          _: 1 /* STABLE */
        }),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(PriceFilter, {
                  "default-value": formData.value.maxPrice,
                  currency: currencyType.value
                }, null, 8 /* PROPS */, ["default-value", "currency"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (isHotelListPage.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 3,
              title: dealsFilterTitle.value
            }, {
              default: _withCtx(() => [
                _createVNode(DealsFilter, {
                  label: _unref(translate)('filtersSection.dealsOnly.label'),
                  class: "list-filters__deals"
                }, null, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["title"]))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, { title: "Flexibel buchen" }, {
          default: _withCtx(() => [
            _createVNode(FlexFilter)
          ]),
          _: 1 /* STABLE */
        }),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 4,
              title: "Beliebteste Filter",
              "delete-value": "mostPopularFilters",
              "delete-button-string": "mostPopularFilters",
              "is-checked": selectedPopularFilter.value
            }, {
              default: _withCtx(() => [
                _createVNode(MostPopularFilters, {
                  class: "list-filters__most-popular",
                  filters: formData.value.mostPopularFilters,
                  "onUpdate:isChecked": handlePopularFilterUpdate
                }, null, 8 /* PROPS */, ["filters"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["is-checked"]))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 5,
              title: "Hotelkategorie"
            }, {
              default: _withCtx(() => [
                _createVNode(HotelCategoryFilter, { "show-suns": _unref(showSuns) }, null, 8 /* PROPS */, ["show-suns"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 6,
              title: "Weiterempfehlungsrate"
            }, {
              default: _withCtx(() => [
                _createVNode(RecommendationRateFilter)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (!isOfferList.value)
          ? (_openBlock(), _createBlock(FilterContainer, {
              key: 7,
              "delete-value": "customerRating",
              "delete-button-string": "customerRating",
              title: "Kundenbewertungen",
              "is-checked": selectedRatingFilter.value
            }, {
              default: _withCtx(() => [
                _createVNode(CustomerRatingFilter, { "onUpdate:isChecked": handleRatingFilterUpdate })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["is-checked"]))
          : _createCommentVNode("v-if", true),
        (showDestinationFilter.value)
          ? (_openBlock(), _createBlock(FilterContainer, { key: 8 }, {
              default: _withCtx(() => [
                (destinationFilterLoading.value)
                  ? (_openBlock(), _createBlock(SkeletonLoader, {
                      key: 0,
                      class: "list-filters__geo-skeleton",
                      "loader-width": 31.8,
                      "loader-height": 7,
                      "border-radius": '1rem'
                    }))
                  : (_openBlock(), _createBlock(DestinationFilter, {
                      key: 1,
                      title: _ctx.destinationLabel,
                      "location-type": locationType.value,
                      disabled: !destinationTypes.value || destinationTypes.value.length == 0
                    }, null, 8 /* PROPS */, ["title", "location-type", "disabled"]))
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(FilterContainer, null, {
          default: _withCtx(() => [
            (!tourOperatorLoading.value)
              ? (_openBlock(), _createBlock(OperatorFilter, {
                  key: 0,
                  class: "list-filters__operator",
                  disabled: !operatorTypes.value || operatorTypes.value.length === 0
                }, null, 8 /* PROPS */, ["disabled"]))
              : _createCommentVNode("v-if", true),
            (tourOperatorLoading.value)
              ? (_openBlock(), _createBlock(SkeletonLoader, {
                  key: 1,
                  class: "list-filters__operator-skeleton",
                  "loader-width": 31.8,
                  "loader-height": 7,
                  "border-radius": '1rem'
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ])
    ])
  ]))
}
}

})