<template>
	<div
		ref="mapDiv"
		class="map-with-offers"
	></div>
	<MapOfferButton
		v-for="(o, index) in offers"
		:key="o.Hotel.IffCode"
		ref="markerComponents"
		:show-suns="showSuns"
		:item="o"
		:index="index"
		:map="map"
		:default-open="o.Hotel.IffCode === props.hotelToShowIffCode"
		:should-navigate="o.Hotel.IffCode !== props.hotelToShowIffCode"
		:is-hotel-page
		@MapOfferButton:Click="closeAllInfoWindow(o.Hotel.IffCode)"
	/>
</template>

<script lang="ts" setup>
import { onMounted, ref, onBeforeMount, watch, nextTick } from 'vue';
import { debounce } from '@utils/utils';
import { isClientGermany } from '@utils/environmentUtils';
import { extractTravelTypeFromOffer } from '@utils/offerUtils';
import * as hotellistService from '@services/bestOfferForAreaService';
import MapOfferButton from '@lmt-rpb/MapOfferButton/MapOfferButton.vue';
import type { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import type { ApiDataTypes } from '@/components/common/types';
import importMapsLibary from './importMapsLibraryScript';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	id: string;
	lat: number;
	lng: number;
	northwest: { lat: number; lng: number } | null;
	southeast: { lat: number; lng: number } | null;
	hotelRequestBody: ApiDataTypes;
	hotelToShowIffCode?: number;
	hotelToShow?: ItemType | null;
	isHotelPage?: boolean;
}

interface Coordinate {
	Latitude: number;
	Longitude: number;
}

interface Area {
	LowerLeft: Coordinate;
	UpperRight: Coordinate;
}

const props = withDefaults(defineProps<Props>(), { hotelToShow: undefined, hotelToShowIffCode: undefined, isHotelPage: false });
const offers = ref<ItemType[]>([]);
const markerComponents = ref<InstanceType<typeof MapOfferButton>[]>();
const mapDiv = ref<HTMLDivElement>();
const mapImported = ref(false);
let map: google.maps.Map;
const showSuns = isClientGermany();

function importGoogleMaps() {
	if (!mapImported.value) {
		mapImported.value = true;
		importMapsLibary({ key: process.env.googleMapsKey, v: 'weekly' });
	}
}

async function initMap() {
	await google.maps.importLibrary('maps');
	await google.maps.importLibrary('marker');

	return new google.maps.Map(mapDiv.value!, {
		zoom: 7,
		mapId: props.id,
		gestureHandling: 'greedy',
	});
}

async function handleMapResize() {
	closeAllInfoWindow();
	offers.value = [];
}
const { isSmallMobile } = storeToRefs(useBreakpointStore());

watch(isSmallMobile, handleMapResize);

function setArea() {
	if (props.northwest && props.southeast) {
		const bounds = new google.maps.LatLngBounds();
		bounds.extend(props.northwest);
		bounds.extend(props.southeast);
		map.fitBounds(bounds);
	}
}

function addGoogleMapsListener() {
	google.maps.event.addListener(
		map,
		'bounds_changed',
		debounce(async () => addNewMarker(), 400)
	);
	google.maps.event.addListener(map, 'click', () => {
		closeAllInfoWindow();
	});
}

const processOffer = (offer: ItemType) => {
	const pricing = offer.Pricing?.Total;
	const travelOffer = extractTravelTypeFromOffer(offer);
	const offerPricing = {
		Converted: travelOffer?.Pricing?.Total?.Converted,
		Amount: travelOffer?.Pricing?.Total?.Amount,
	};
	return {
		...offer,
		computedPrice: pricing?.Converted ?? pricing?.Amount ?? offerPricing.Converted ?? offerPricing.Amount,
	};
};

async function addNewMarker() {
	try {
		const area = getGeoLocationArea();
		const response = await hotellistService.getByGeoLocation(props.hotelRequestBody, area);
		const newOffers = (
			response?.Offers?.filter(
				(newOffer) => !offers.value.find((savedOffers) => savedOffers.Hotel.IffCode === newOffer.Hotel.IffCode)
			) || []
		).map(processOffer);
		offers.value.push(...newOffers);
	} catch (e) {
		if (e instanceof Error) {
			console.error(e.message);
		}
	}
}

function getGeoLocationArea() {
	const bounds = map.getBounds();
	let area: Area | null = null;
	if (bounds) {
		const northeast = bounds.getNorthEast();
		const southwest = bounds.getSouthWest();
		area = {
			UpperRight: { Longitude: northeast.lng(), Latitude: northeast.lat() },
			LowerLeft: { Longitude: southwest.lng(), Latitude: southwest.lat() },
		};
	}
	return area;
}

function closeAllInfoWindow(notCloseId?: number) {
	markerComponents.value?.forEach((m) => {
		if (m.props.item.Hotel.IffCode !== notCloseId) {
			m.buttonClicked = false;
			m.setActive(false);
		}
	});
}

async function openDefaultInfoWindow() {
	if (!props.hotelToShowIffCode && !props.hotelToShow) return;
	closeAllInfoWindow(props.hotelToShowIffCode);
	if (props.hotelToShow) {
		const processedOffer = processOffer(props.hotelToShow);
		offers.value = [processedOffer];
	} else {
		offers.value = [];
	}
	await addNewMarker();
	const defaultMarker = markerComponents.value?.find((marker) => marker.props.item.Hotel.IffCode === props.hotelToShowIffCode);
	if (defaultMarker) {
		defaultMarker.setActive(true);
		defaultMarker.buttonClicked = true;
	}
}

onBeforeMount(() => {
	importGoogleMaps();
});

onMounted(async () => {
	map = await initMap();
	setArea();
	if (props.hotelToShow) {
		const processedOffer = processOffer(props.hotelToShow);
		offers.value = [processedOffer];
	}
	addGoogleMapsListener();
	await nextTick();
});

defineExpose({
	openDefaultInfoWindow,
});
</script>
<style scoped>
.map-with-offers {
	height: 100%;
	width: 100%;
}
</style>
