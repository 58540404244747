<template>
	<component
		:is="as"
		:class="['link-button', blockClassModifiers]"
		v-bind="{ ...defaults, ...$attrs }"
	>
		<span class="link-button__text">
			<slot>{{ label }}</slot>
		</span>
		<FaIcon
			v-if="withIcon"
			class="link-button__icon"
			:icon="['fa-solid', 'fa-chevron-right']"
		/>
	</component>
</template>
<script lang="ts" setup>
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import { computed } from 'vue';

export interface Props {
	as?: 'a' | 'button';
	label?: string;
	withIcon?: boolean;
	theme?: 'primary' | 'inherit';
}
const { as = 'button', withIcon = true, theme = 'primary' } = defineProps<Props>();

const defaults = computed(() => (as === 'button' ? { type: 'button' } : {}));
const blockClassModifiers = computed(() => ({
	[`link-button--${theme}`]: theme,
}));
</script>
<style lang="scss" scoped>
.link-button {
	text-align: right;
	color: $color-text-upselling;
	padding: 0;
	background: none;
	border: none;
	display: inline-flex;
	place-items: center start;
	gap: 0.8rem;
	outline: inherit;

	& > * {
		pointer-events: none;
	}

	&__text {
		font-size: $font-small-2;
		line-height: 2.6rem;
		text-decoration: underline;

		&:empty {
			display: none;
		}
	}

	&__icon {
		width: 1.2rem;
		height: 1.2rem;
		color: currentcolor;
		text-align: center;
	}

	&--inherit {
		color: inherit;
	}
}
</style>
