import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-modal__content" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "base-modal__header-slot" }
const _hoisted_4 = { class: "base-modal__main" }
const _hoisted_5 = { class: "base-modal__footer" }

import { ref, onMounted, computed } from 'vue';
import Modal from '@/js/modules/modal';
import CloseButton from '@lmt-rpb/Atoms/CloseButton/CloseButton.vue';
import LinkButton, { type Props as LinkButtonProps } from '@lmt-rpb/Atoms/LinkButton/LinkButton.vue';

interface Props {
	headerTitle?: string;
	mediaQuery?: MediaQueryList | null;
	fullHeight?: boolean;
	withOpenButton?: boolean;
	fullScreen?: boolean;
	openButtonProps?: LinkButtonProps & { class?: string };
	modalCloseCallback?: () => void;
	size?: 'small';
}


export default /*@__PURE__*/_defineComponent({
  ...{
	inheritAttrs: false,
},
  __name: 'BaseModal',
  props: {
    headerTitle: { default: '' },
    mediaQuery: { default: null },
    fullHeight: { type: Boolean, default: false },
    withOpenButton: { type: Boolean },
    fullScreen: { type: Boolean },
    openButtonProps: { default: () => ({}) },
    modalCloseCallback: { type: Function, default: () => {} },
    size: { default: undefined }
  },
  emits: ['close', 'open'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const modalRef = ref<HTMLDialogElement | null>(null);
let modalInstance: Modal | null = null;


const emits = __emit;
const props = __props;

const openModal = () => {
	emits('open');
	modalInstance?.openModal();
};

const closeModal = () => {
	modalInstance?.closeModal();
	emits('close');
};

const blockClassModifiers = computed(() => ({
	[`base-modal--${props.size}`]: !!props.size,
	'base-modal--full-height': props.fullHeight,
	'base-modal--full-screen': props.fullScreen,
}));

__expose({
	modalRef,
	openModal,
	closeModal,
});

onMounted(() => {
	if (modalRef.value) {
		modalInstance = new Modal(modalRef.value, props?.mediaQuery, props.modalCloseCallback);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "open-button", _normalizeProps(_guardReactiveProps({ openModal: openModal })), () => [
      (_ctx.withOpenButton)
        ? (_openBlock(), _createBlock(LinkButton, _mergeProps({
            key: 0,
            class: "base-modal__open-btn",
            label: "Open Modal"
          }, _ctx.openButtonProps, {
            onClick: _withModifiers(openModal, ["stop"])
          }), null, 16 /* FULL_PROPS */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("dialog", _mergeProps({
      ref_key: "modalRef",
      ref: modalRef
    }, _ctx.$attrs, {
      class: ['base-modal', blockClassModifiers.value]
    }), [
      _createElementVNode("div", {
        class: "base-modal__backdrop",
        onClick: _withModifiers(closeModal, ["stop"])
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "base-modal__header",
          onClick: _withModifiers(closeModal, ["stop"])
        }, [
          _createElementVNode("span", {
            class: "base-modal__header-title",
            textContent: _toDisplayString(_ctx.headerTitle)
          }, null, 8 /* PROPS */, _hoisted_2),
          _createVNode(CloseButton, {
            class: "base-modal__close-btn",
            onClick: _withModifiers(closeModal, ["stop"])
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps({ closeModal: closeModal, openModal: openModal })))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ closeModal: closeModal, openModal: openModal })))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "footer", _normalizeProps(_guardReactiveProps({ closeModal: closeModal, openModal: openModal })))
        ])
      ])
    ], 16 /* FULL_PROPS */)
  ], 64 /* STABLE_FRAGMENT */))
}
}

})