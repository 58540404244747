<template>
	<div class="feature-list">
		<div
			v-if="!(location === 'checkoutSummary') && !isLongDate && isNumber(duration)"
			class="feature-list__duration"
		>
			{{ pluralize(duration ?? 0, 'Tag', 'Tage') }}
		</div>
		<div
			v-else-if="isLongDate"
			class="feature-list__duration"
		>
			{{ duration }}
		</div>
		<ul v-if="detailList">
			<OfferFeature
				v-for="(feature, index) in detailList"
				:key="index"
				:feature="feature"
				:feature-bold="isFirstFeatureBold && index === 0"
				:location="location"
				@mouseover="toggleTooltip(feature.text, true)"
				@mouseleave="toggleTooltip(feature.text, false)"
			>
				<span
					v-if="feature.text !== '100 € Gutschein'"
					class="offer-feature__text"
				>
					{{ normalizeHotelAttributes(feature.text) }}
				</span>
				<span
					v-if="feature.text == '100 € Gutschein'"
					class="offer-feature__text"
				>
					{{ formatPrice(100, 'EUR') }} Gutschein
					<InfoTooltip
						class="offer-feature__tooltip"
						:show-tooltip-parent="showTooltip"
					>
						Mit Ihrer Reisebuchung erhalten Sie als Dankeschön einen {{ formatPrice(100, 'EUR') }} Gutschein für Ihre nächste
						Reise.
						<div>
							<a
								:href="voucherConditionsLink"
								target="_blank"
							>
								Hier gehts zu den Gutscheinbedingungen.
							</a>
						</div>
					</InfoTooltip>
				</span>
			</OfferFeature>
		</ul>
		<div
			v-for="(feature, index) in offerFeaturesArray"
			:key="index"
		>
			<FlexFeature :feature="feature.feature" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import OfferFeature from '@lmt-rpb/OfferFeature/OfferFeature.vue';
import FlexFeature from '@lmt-rpb/FlexFeature/FlexFeature.vue';
import type { PageLocation } from '@global-js/constants';
import { NDASH } from '@global-js/constants';
import type { OfferData } from '@/interfaces/checkout/offer-data';
import { useStore } from '@/components/common/store';
import type { ViewType } from '@/js/data/view-types';
import viewTypes from '@/js/data/view-types';
import { normalizeHotelAttributes } from '@/js/utils/arrayUtils';
import { boardTypes } from '@/js/data/index';
import { convertFeaturesIntoArray, pluralize } from '@/js/utils/utils';
import { formatDate } from '@utils/dateUtils';
import type { MergedOfferItemData } from '@/interfaces/offer';
import type { ShoppingCart } from '@/interfaces/checkout/shopping-cart';
import { formatPrice } from '@/js/utils/priceUtils';
import type { DetailList, FeatureOfferType } from '@/interfaces/components/featureListData';
import InfoTooltip from '../InfoTooltip/InfoTooltip.vue';
import type { Feature } from '../OfferFeature/offer-feature';
import { getBaseUrl, isRpbClient } from '@utils/environmentUtils';

type Props = {
	offer: FeatureOfferType;
	isFirstFeatureBold?: boolean;
	location?: PageLocation;
	isLongDate?: boolean;
};

const props = defineProps<Props>();

const store = useStore();

const voucherConditionsLink = computed(() => {
	let url = getBaseUrl() + '/gutschein-einloesen/';
	if (isRpbClient()) {
		//only rb has this "sub"-path
		url += 'geld-zurueck-gutschein/';
	}
	return url + '#bedingungen';
});

const duration = ref<number | string>(0);
const seaView = ref<string>('');
const offerData = ref<FeatureOfferType>({});
const offerFeaturesArray = ref<Feature[]>([]);
const boardType = ref<string>('');
const showTooltip = ref(false);
const flexStornoChecked = computed(() => store.state.checkout.FlexStornoChecked);
const isCheckoutLocation = ['checkoutInfoBox', 'checkoutSummary', 'thankyouPage'].includes(props.location ?? '');

const isHotelOnly = !isCheckoutLocation ? computed((): boolean => store.state.searchMask.onlyHotel) : ref(false);

function isNumber(value: any): boolean {
	return typeof value === 'number' && !Number.isNaN(value);
}

function computeDuration() {
	let durationValue = 0;
	const dateHelper = (startDate?: Date, endDate?: Date, duration?: number) => {
		if (!startDate || !endDate) {
			return NDASH;
		}
		const durationLong = duration ? ` (${pluralize(duration, 'Tag', 'Tage')})` : '';
		return `${formatDateLong(startDate)} ${NDASH} ${formatDateLong(endDate)}${durationLong}`;
	};

	if (props.isLongDate && isOfferData(props.offer)) {
		return dateHelper(
			new Date((props.offer as OfferData).Offer.StartDate),
			new Date((props.offer as OfferData).Offer.EndDate),
			props.offer.Offer.StayDuration
		);
	}

	if (isOfferData(props.offer)) durationValue = props.offer.Offer.StayDuration || props.offer.Offer.OvernightStays || 0;
	else if (isMergedOfferItemData(props.offer)) durationValue = props.offer.duration || props.offer.StayDuration || 0;
	else if (isShoppingCart(props.offer)) {
		if (props.isLongDate) {
			const travel = props.offer.Travel;
			return dateHelper(new Date(travel.StartDate), new Date(travel.EndDate), travel.Duration);
		}
		durationValue = props.offer.Travel.Duration || 0;
	}
	return durationValue;
}

function computeSeaView() {
	let seaViewValue = '';
	if (isOfferData(props.offer)) seaViewValue = props.offer.Offer.Room.View || '';
	else if (isMergedOfferItemData(props.offer)) seaViewValue = props.offer.Room.View || '';
	else if (isShoppingCart(props.offer)) seaViewValue = props.offer.Travel.Hotel.RoomView || '';
	return seaViewValue;
}

function computeOffer() {
	let offerValue = {};
	if (isOfferData(props.offer)) offerValue = props.offer.Offer as unknown as OfferData;
	else if (isShoppingCart(props.offer)) offerValue = props.offer as ShoppingCart;
	else if (isMergedOfferItemData(props.offer)) offerValue = props.offer as MergedOfferItemData;
	return offerValue;
}

function computeOfferFeaturesArray(flexStornoSelected?: boolean) {
	const isThankYouPage = props.location === 'thankyouPage';
	const excludeFlexCancellation = isThankYouPage && !props.offer?.Pricing?.OptionalFlexRate?.Amount;

	const offerFeatures = {
		...(offerData.value?.OfferFeatures || {}),
		...(offerData.value?.Travel?.OfferFeatures || {}),
	};

	if (offerData.value?.Travel?.OfferFeatures && offerData.value?.OptionalFlexRate?.Description) {
		offerFeatures.OptionalFlexRate = offerData.value.OptionalFlexRate.Description;
	}

	if (excludeFlexCancellation) {
		delete offerFeatures.OptionalFlexRate;
	}

	const featuresArray = convertFeaturesIntoArray(offerFeatures, store.state.checkout.FlexStorno, flexStornoSelected, isThankYouPage);

	return featuresArray
		? featuresArray.map((feature, index) => ({
				id: index,
				checked: true,
				text: feature.title,
				feature: { title: feature.title, description: feature.description },
			}))
		: [];
}

function computeBoardType() {
	return (
		boardTypes.find((type) => {
			const mealType = isShoppingCart(props.offer) ? props.offer.Travel.Hotel.MealType : offerData.value.BoardType;
			return type.value.toLowerCase() === mealType.toLowerCase();
		})?.label || ''
	);
}

function getCommonList(RoomName: string, OfferIncludes: string[], OfferForLimitedMobility: boolean, TransferInfos?: string[]): DetailList {
	const isTransferIncluded = OfferIncludes?.includes('transfer') || TransferInfos?.includes('TransferIncluded');
	const isRentalCarIncluded = OfferIncludes?.includes('rentalcar');

	return [
		{
			id: -1,
			checked: true,
			text: `1x ${normalizeHotelAttributes(RoomName)}`,
			icon: ['fa-solid', 'fa-bed'],
		},
		{
			id: 20,
			checked: true,
			text: boardType.value,
			icon: ['fa-solid', 'fa-utensils'],
		},
		{
			id: 30,
			checked: isTransferIncluded,
			text: isTransferIncluded ? 'Inkl. Hoteltransfer' : 'Ohne Hoteltransfer',
			icon: isTransferIncluded ? ['fa-solid', 'fa-bus-simple'] : ['fa-kit', 'fa-no-transfer'],
		},
		...((isRentalCarIncluded
			? [
					{
						id: 31,
						checked: true,
						text: 'Mietwagen inklusive',
						icon: ['fa-solid', 'fa-car'],
					},
				]
			: []) satisfies DetailList),
		...((OfferIncludes?.includes('railandfly')
			? [
					{
						id: 40,
						checked: true,
						text: 'Zug zum Flug (innerh. Deutschlands)',
						icon: ['fa-solid', 'fa-train-subway'],
					},
				]
			: []) satisfies DetailList),
		...((isCheckoutLocation
			? [
					{
						id: 35,
						checked: OfferForLimitedMobility,
						text: OfferForLimitedMobility
							? 'Geeignet für Personen mit eingeschränkter Mobilität.'
							: 'Nicht geeignet für Personen mit eingeschränkter Mobilität.',
						icon: OfferForLimitedMobility ? ['fa-solid', 'fa-wheelchair'] : ['fa-solid', 'fa-triangle-exclamation'],
					},
				]
			: []) satisfies DetailList),
	];
}

const detailList = computed<DetailList>(() => {
	const flexStorno = flexStornoChecked.value;
	const isNotUnverifiedOffer = props.location !== 'unverifiedOffer';
	const isCheckoutSummary = props.location === 'checkoutSummary';
	const isThankYouPage = props.location === 'thankyouPage';

	let list: DetailList = [];

	if (isShoppingCart(props.offer)) {
		const offer = props.offer;
		if (offer && offer.Travel.TravelType === 'Accommodation') {
			isHotelOnly.value = true;
		}
		if (offer && offer.Travel && offer.Travel.Hotel && offer.Travel.Hotel.RoomName) {
			const {
				Hotel: { RoomName },
				OfferIncludes = [],
				OfferForLimitedMobility,
			} = offer.Travel;
			list = getCommonList(RoomName, OfferIncludes, OfferForLimitedMobility);
		}
	} else {
		const offer = offerData.value;
		if (offer && offer.Room && offer.Room.Name) {
			const {
				Room: { Name: RoomName },
				OfferIncludes,
				OfferForLimitedMobility,
				TransferInfos,
			} = offer;
			list = getCommonList(RoomName, OfferIncludes, OfferForLimitedMobility, TransferInfos);
		}
	}

	if (isNotUnverifiedOffer) {
		list.push({ id: 50, checked: true, text: '100 € Gutschein', icon: ['fa-solid', 'fa-badge-percent'] });
	}

	if (isCheckoutSummary && isOfferData(props.offer) && props.offer.Offer.OutboundFlightSegments) {
		const { OutboundFlightSegments, InboundFlightSegments } = props.offer.Offer;
		list.push({
			id: -3,
			checked: true,
			text: 'inkl. Flug ab ' + OutboundFlightSegments[0].Departure.Airport,
			icon: ['fa-solid', 'fa-plane'],
		});
		const date = duration.value === 1 ? '1 Tag' : duration.value + ' Tage';
		const dateDeparture = formatDateLong(OutboundFlightSegments[0].Departure.Date);
		const dateInbound = formatDateLong(InboundFlightSegments[InboundFlightSegments.length - 1].Arrival.Date);
		list.push({ id: -2, checked: true, text: `${date}, ${dateDeparture} - ${dateInbound}`, icon: ['fa-regular', 'fa-calendar-range'] });
	}

	viewTypes.forEach((viewType: ViewType) => {
		if (seaView.value === viewType.value) {
			list.push({ id: 11, checked: true, text: viewType.label, icon: ['fa-solid', 'fa-water'] });
		}
	});

	if (!flexStorno && !isThankYouPage) {
		list = list.filter((item) => item.id !== 60);
	}

	if (isHotelOnly.value || (isOfferData(props.offer) && props.offer.Offer.TravelType === 'Accommodation')) {
		list = list.filter((item) => item.text !== 'Ohne Hoteltransfer');
	}

	return organizeDetailList(list);
});

function organizeDetailList(list: DetailList): DetailList {
	// Sort by checked first, then by id
	list.sort((a, b) => {
		if (a.checked !== b.checked) {
			return a.checked ? -1 : 1;
		}
		return a.id - b.id;
	});

	// Transfer (included or not) should always come after the catering option
	const cateringIndex = list.findIndex((item) => item.id === 20);
	const transferIndex = list.findIndex((item) => item.id === 30);

	if (cateringIndex !== -1 && transferIndex !== -1) {
		if (transferIndex !== cateringIndex + 1) {
			const [transferItem] = list.splice(transferIndex, 1);
			list.splice(cateringIndex + 1, 0, transferItem);
		}
	}

	return list;
}

const toggleTooltip = (text: string, state: boolean) => {
	if (text === '100 € Gutschein') {
		showTooltip.value = state;
	}
};

const isOfferData = (data?: FeatureOfferType): data is OfferData => (data as OfferData).Offer !== undefined;
const isShoppingCart = (data?: FeatureOfferType): data is ShoppingCart => (data as ShoppingCart).ShoppingCartID !== undefined;
const isMergedOfferItemData = (data?: FeatureOfferType): data is MergedOfferItemData => (data as MergedOfferItemData).OfferID !== undefined;

const formatDateLong = (date: string | Date | undefined) =>
	date
		? formatDate(date, {
				weekday: 'short',
				month: '2-digit',
				day: '2-digit',
				year: '2-digit',
			}).replace(',', '')
		: '.';

onMounted(() => {
	duration.value = computeDuration();
	seaView.value = computeSeaView();
	offerData.value = computeOffer();
	offerFeaturesArray.value = computeOfferFeaturesArray();
	boardType.value = computeBoardType();
});

watch(flexStornoChecked, (value) => {
	offerFeaturesArray.value = computeOfferFeaturesArray(value);
});

watch(
	() => store.state.checkout.FlexStorno,
	() => {
		offerFeaturesArray.value = computeOfferFeaturesArray();
	}
);
</script>

<style lang="scss" scoped>
.feature-list {
	list-style: none;
	margin: 0;
	padding: 0;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li:nth-child(1) {
			display: inline-flex;
		}
	}

	&__duration {
		font-weight: $font-weight-semibold;
		color: $color-text;
		font-size: $font-medium-2;
	}

	.offer-feature__tooltip {
		margin-left: 0.5rem;

		:deep(.info-tooltip__tooltip) {
			display: flex;
			flex-direction: column;
			gap: 0.8rem;

			a {
				text-decoration: underline;
				color: inherit;
			}
		}
	}

	.offer-feature__text {
		display: inline-flex;
	}
}
</style>
