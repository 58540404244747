<template>
	<div
		class="month"
		:class="{ 'best-price-calendar': bestPriceCalendar }"
	>
		<h2
			v-if="!bestPriceCalendar"
			class="month__title"
		>
			{{ title }}
		</h2>
		<table class="month__table">
			<tr v-if="horizontal || (!isDesktop && !bestPriceCalendar)">
				<th
					v-for="(day, key) in daysOfTheWeek"
					:key="key"
					:aria-label="day"
					class="month__weekday"
					scope="col"
				>
					{{ shortifyWeekday(day) }}
				</th>
			</tr>
			<tr
				v-for="week in weekAmount"
				:key="`${year}-${month}-week-${week}`"
			>
				<td
					v-for="(day, dayIndex) in daysForWeek(week)"
					:key="`${year}-${month}-${dayIndex}`"
					:data-date="day?.date.getTime()"
					:class="getClass(day)"
					class="month__day-cell"
					@click.prevent.stop="onSelect(day)"
					@keydown.space.prevent.stop="onSelect(day)"
					@keydown.enter.prevent.stop="onSelect(day)"
				>
					{{ day?.date?.getDate() }}
					<div
						v-if="bestPriceCalendar"
						class="month__day-price"
						:class="{ isCHF: day?.currencyCode === 'CHF' }"
					>
						<span v-if="!isDisabled(day)">{{ formatPrice(day?.pricing ?? null, day?.currencyCode) }}</span>
						<span v-else> &nbsp; </span>
					</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<script lang="ts" setup>
import { formatDate, dateDiff, now, shortifyWeekday } from '@utils/dateUtils';
import { computed } from 'vue';
import type { BestAccommodationOfferForCheckInDate } from '@/interfaces/api/v1-best-accommodation-offer-for-check-in-date';
import { formatPrice, getTotalPrice } from '@/js/utils/priceUtils';
import type { SelectionType } from '@/components/common/types';
import { rangeArray } from '@/js/utils/arrayUtils';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';
import { useTranslations } from '@helper/composables/translations';

interface Props {
	selection: SelectionType;
	year: number;
	month: number;
	horizontal: boolean;
	offers?: BestAccommodationOfferForCheckInDate[];
	cheapestOffer?: number;
	bestPriceCalendar?: boolean;
}
const { translate } = useTranslations();

const getClass = (day: WeekElement) => {
	if (!day) {
		return {
			'is-empty': true,
		};
	}
	const { date, price } = day;
	return {
		'is-disabled': isDisabled(day),
		'is-selected': isSelected(date),
		'is-from-selection': isFromSelection(date),
		'is-to-selection': isToSelection(date),
		'is-cheapest': props.cheapestOffer === price,
	};
};

const props = withDefaults(defineProps<Props>(), {
	offers: () => [],
	cheapestOffer: -1,
	bestPriceCalendar: false,
});
const emit = defineEmits(['select']);

const daysInCalenderMonth = computed(() =>
	rangeArray(-firstWeekDay.value - 6, daysInMonth.value).map((day: number) => (day <= 0 ? null : day))
);

const daysForWeek = (week: number): (WeekElement | null)[] => {
	const days = daysInCalenderMonth.value.slice((week - 1) * 7, week * 7);
	if (days.every((d) => d === null)) {
		return [];
	}
	return days.map((d) => {
		if (d === null) {
			return null;
		}
		const date = new Date(props.year, props.month, d);
		const foundOffer = props.offers.find((offer) => !dateDiff(offer.StartDate, date));
		if (foundOffer) {
			const totalPrice = getTotalPrice(foundOffer.Pricing ?? {});
			return {
				date,
				price: Math.round(foundOffer.Price),
				pricing: Math.round(totalPrice),
				currencyCode: foundOffer.CurrencyCode,
			};
		}
		return { date };
	});
};

const { isDesktop } = storeToRefs(useBreakpointStore());
const daysInMonth = computed((): number => new Date(props.year, props.month + 1, 0).getDate());
const weekAmount = computed((): number => Math.ceil(daysInCalenderMonth.value.length / 7));

const daysOfTheWeek = computed(() => [
	translate('calendar.daysOfWeek.monday'),
	translate('calendar.daysOfWeek.tuesday'),
	translate('calendar.daysOfWeek.wednesday'),
	translate('calendar.daysOfWeek.thursday'),
	translate('calendar.daysOfWeek.friday'),
	translate('calendar.daysOfWeek.saturday'),
	translate('calendar.daysOfWeek.sunday'),
]);

const title = computed((): string => {
	const monthName = formatDate(new Date(2000, props.month, 1), { month: 'long' });
	return `${monthName} ${props.year}`;
});

const firstWeekDay = computed((): number => new Date(props.year, props.month).getDay() - 1); // Minus 1 because our week in the calendar starts always on monday

type WeekElement = { date: Date; price?: number; pricing?: number; currencyCode?: string } | null;

const isDisabled = (day: WeekElement): boolean => {
	if (!day) {
		return true;
	}
	const today = now(true);
	return day.date.getTime() <= +today || (day?.price === undefined && props.bestPriceCalendar);
};

const onSelect = (day: WeekElement) => {
	if (!day) {
		return;
	}
	if (!isDisabled(day)) {
		emit('select', day?.date);
	}
};

const isSelected = (day?: Date): boolean => {
	if (!props.selection.from || !props.selection.to || !day) {
		return false;
	}
	return (isFromSelection(day) && isToSelection(day)) || (props.selection.from <= day.getTime() && props.selection.to >= day.getTime());
};

const isFromSelection = (day?: Date): boolean => {
	if (!props.selection.from || !day) {
		return false;
	}

	return new Date(props.selection.from).toDateString() === day?.toDateString();
};

const isToSelection = (day?: Date): boolean => {
	if (!props.selection.to || !day) {
		return false;
	}

	return new Date(props.selection.to).toDateString() === day.toDateString();
};
</script>

<style lang="scss" scoped>
.offer-duration__day-names {
	position: relative;
	display: flex;
	max-width: 50rem;
	margin: 0 auto;
	padding: 0;
	flex-wrap: wrap;
	border-bottom: 1px solid #f9f9f9;

	@media (min-width: $breakpoint-verysmall) {
	}
}

.offer-duration__day {
	padding: 1rem;
	flex: 1 1 auto;
	text-align: center;
	font-weight: $font-weight-bold;
}

.month {
	padding: 0 1.5rem;

	&__title {
		position: sticky;
		top: 0;
		padding: 1rem 0;
		background: $color-white;
		color: $color-primary;
		font-variant: lining-nums;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		text-transform: uppercase;
		z-index: 1;
	}

	&__table {
		width: 100%;
		border-spacing: 0.25rem;
		border-collapse: separate;
	}

	&__weekday {
		padding: 1rem;
		font-weight: $font-weight-semibold;
		text-align: center;
	}

	&__day-cell {
		border: none;
		background: none;
		color: $color-text;
		font-size: $font-small-2;
		font-weight: $font-weight-semibold;
		line-height: 3.3rem;
		text-align: center;
		cursor: pointer;

		&.is-selected {
			background: $color-primary-l4;
		}

		&.is-disabled {
			color: $color-black-t4;
			cursor: not-allowed;
		}

		&.is-from-selection {
			border-radius: $border-radius-button 0 0 $border-radius-button;
			background: $color-primary;
			color: $color-white;
		}

		&.is-to-selection {
			border-radius: 0 $border-radius-button $border-radius-button 0;
			background: $color-extra;
			color: $color-white;
		}
	}
}

.best-price-calendar {
	.month__table {
		border-collapse: collapse;
		border-radius: $border-radius-large;
	}

	.month__weekday {
		text-align: center;
		width: 5.4rem;
		height: 4.8rem;
		color: $color-placeholder-text;
	}

	.month__day-cell {
		position: relative;
		border: 0.1rem solid $color-vivid;
		color: $color-text-l24;
		line-height: unset;
		transition: background-color 0.2s;
		text-align: center;
		padding-top: 1.3rem;
		padding-bottom: 0.3rem;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			border-top: 0 solid transparent;
			border-right: 2.2rem solid transparent;
			border-color: transparent;
			transition: border-top 0.25s;
			transition-delay: 0.3s;
		}

		&.is-cheapest::before {
			border-top: 2.2rem solid $color-extra;
		}

		&.is-disabled {
			color: $color-black-t4;
		}

		.month__day-price {
			font-weight: normal;
			font-size: 0.9rem;
			color: $color-primary;

			&.isCHF {
				margin: 0 auto;
				max-width: 3.3rem;
			}
		}

		&.is-cheapest .month__day-price {
			color: $color-extra;
			transition: all 0.25s;
		}

		&:hover {
			background: $color-primary;
			color: $color-white;
		}

		&.is-empty:hover,
		&.is-disabled:hover {
			background: transparent;
			color: $color-black-t4;
		}

		&:hover .month__day-price {
			color: $color-white;
		}

		&.is-to-selection {
			border-radius: 0 $border-radius-button $border-radius-button 0;
			background: $color-extra;
			color: $color-white;
		}

		&.is-selected {
			border-radius: 0;
			background: $color-extra;

			.month__day-price {
				color: $color-white;
			}
		}
	}

	@media (min-width: $breakpoint-mobilelarge) {
		.month__day-cell .month__day-price {
			font-size: 1.1rem;

			&.isCHF {
				max-width: 4rem;
			}
		}
	}

	@media (min-width: $breakpoint-small) {
		.month__day-cell .month__day-price {
			&.isCHF {
				max-width: none;
			}
		}
	}
}
</style>
