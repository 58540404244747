<template>
	<svg
		width="589"
		height="557"
		viewBox="0 0 589 557"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			width="589"
			height="557"
			fill="#F1EBEB"
		/>
		<mask
			id="mask0_2766_63136"
			style="mask-type:alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="589"
			height="557"
		>
			<path
				d="M0 0H589V557H0V0Z"
				fill="#F1EBEB"
			/>
		</mask>
		<g mask="url(#mask0_2766_63136)">
			<path
				class="fill-dark"
				d="M274.917 48.7571L258.438 29.4928C251.777 21.7064 257.31 9.69238 267.557 9.69238H428.572C439.828 9.69238 444.89 23.7938 436.204 30.9526L419.052 45.0892C417.382 46.4657 416.115 48.2682 415.386 50.3059L403.534 83.4221C401.304 89.651 394.454 92.9006 388.22 90.6868L280.02 52.2648C278.041 51.5622 276.282 50.3526 274.917 48.7571Z"
				fill="#FFACCA"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M400.895 89.5881C405.43 91.8844 412.223 92.1208 417.209 91.5486L417.551 94.5291C412.37 95.1236 404.863 94.96 399.54 92.2646C396.83 90.8924 394.569 88.8004 393.595 85.7244C392.629 82.671 393.021 78.9299 395.008 74.4326L397.752 75.6451C395.939 79.7479 395.786 82.7026 396.455 84.819C397.118 86.9127 398.676 88.4645 400.895 89.5881Z"
				fill="white"
			/>
			<path
				class="fill-dark"
				d="M143.699 94.3188C137.85 98.1064 128.49 104.438 123.4 109.295C120.986 111.6 118.27 114.058 116.042 116.543C114.559 118.196 112.794 120.661 110.958 124.313C108.816 128.574 110.11 133.663 113.563 136.952L118.379 141.538C129.879 151.538 171.431 188.708 186.879 194.038C193.388 196.542 202.512 192.62 205.705 186.42C210.963 176.211 218.446 161.985 222.312 151.178C222.699 150.095 222.879 148.952 222.879 147.802C222.879 142.935 219.601 138.59 215.371 136.18C199.467 127.12 173.232 103.835 154.39 93.6984C150.996 91.8725 146.933 92.2237 143.699 94.3188Z"
				fill="#FFACCA"
			/>
			<path
				class="fill-dark"
				d="M336.238 379.661L327.094 386.588C324.061 388.886 319.833 388.735 316.989 386.206C303.508 374.214 283.001 355.574 280.88 352.038C278.972 348.858 280.729 347.364 282.535 346.801C283.403 346.53 284.329 346.656 285.209 346.887L335.008 359.97C339.334 361.106 341.887 365.568 340.676 369.873L339.108 375.45C338.636 377.129 337.628 378.608 336.238 379.661Z"
				fill="#FFACCA"
			/>
			<path
				d="M361.379 191.038L382.879 128.038L378.379 125.538L357.379 189.538L361.379 191.038Z"
				fill="white"
			/>
			<path
				d="M262.879 327.538L256.879 325.538L284.379 249.038L310.379 174.538L315.879 176.038L262.879 327.538Z"
				fill="white"
			/>
			<path
				d="M374.879 369.538L426.879 213.538L433.379 215.038L380.879 372.038L374.879 369.538Z"
				fill="white"
			/>
			<path
				d="M263.379 90.5381L168.379 266.538L159.879 263.038L256.379 85.5381L263.379 90.5381Z"
				fill="white"
			/>
			<path
				d="M255.878 80.5386L180.5 -8L171.5 -2L256.878 88.0386L255.878 92.0386L378.878 130.039L624.5 216.692L626.5 210.692L382.378 123.039L263.878 87.0386L255.878 80.5386Z"
				fill="white"
			/>
			<path
				d="M321.878 174.039L627.5 274.192L624 282.192L312.378 181.039L228.378 146.039L21 -5L28.5 -9L232.878 138.039L270.378 151.539L321.878 174.039Z"
				fill="white"
			/>
			<path
				d="M-2.5 52.5L-9.5 59L193.878 207.539L284.378 250.039L604.5 350.192L607.5 342.192L288.378 242.539L199.378 201.039L-2.5 52.5Z"
				fill="white"
			/>
			<path
				d="M169.878 255.539L-6.5 112.5L-30 131L302.878 389.539L400.878 500.039L419.378 484.039L400.878 474.039L317.378 378.039L270.378 343.039L436.878 394.039L456.5 378.039H436.878L261.878 321.039L169.878 255.539Z"
				fill="white"
			/>
			<path
				class="fill-light"
				d="M433.402 372.693L399.127 360.999C395.964 359.92 394.292 356.464 395.41 353.314L413.467 302.427C414.537 299.41 417.788 297.764 420.853 298.689L479.259 316.297C482.754 317.351 484.506 321.254 482.971 324.566L469.219 354.226C468.674 355.402 467.762 356.37 466.62 356.985L438.184 372.297C436.714 373.088 434.981 373.232 433.402 372.693Z"
				fill="#FCF3F6"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M417.838 213.768C417.166 215.389 416.304 217.686 415.21 220.871C413.093 227.028 407.676 231.019 401.719 233.554C395.735 236.1 388.95 237.292 383.624 237.705C381.625 237.861 379.913 239.094 379.279 240.866L368.703 270.389L365.879 269.377L376.454 239.854C377.528 236.856 380.339 234.951 383.392 234.714C388.517 234.317 394.957 233.171 400.544 230.793C406.157 228.405 410.656 224.888 412.373 219.896C413.478 216.68 414.362 214.319 415.066 212.62C415.758 210.95 416.311 209.833 416.768 209.169C416.959 208.893 417.299 208.438 417.813 208.2C418.129 208.054 418.609 207.948 419.129 208.148C419.617 208.335 419.882 208.694 420.01 208.925C420.241 209.341 420.286 209.802 420.301 210.049C420.321 210.356 420.311 210.686 420.287 210.998L419.209 210.915C418.942 211.318 418.497 212.178 417.838 213.768ZM419.336 210.745C419.336 210.745 419.332 210.75 419.325 210.757C419.332 210.749 419.336 210.745 419.336 210.745ZM419.105 210.907C419.095 210.913 419.084 210.918 419.072 210.923C418.87 211.017 418.489 211.116 418.054 210.949C417.949 210.908 417.857 210.859 417.779 210.805L419.105 210.907ZM417.298 210.151C417.298 210.151 417.299 210.156 417.301 210.168C417.298 210.157 417.298 210.151 417.298 210.151Z"
				fill="white"
			/>
			<path
				class="fill-light"
				d="M400.463 214.499L324.833 191.683C321.776 190.761 318.532 192.394 317.453 195.399L302.96 235.745C301.819 238.92 303.52 242.411 306.723 243.47L356.589 259.955C359.358 260.87 362.394 259.665 363.564 256.993C369.457 243.539 372.647 227.454 387.879 231.038C394.095 232.501 399.41 228.336 403.4 223.908C406.228 220.768 404.508 215.72 400.463 214.499Z"
				fill="#FCF3F6"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M201.729 386.192L245.841 333.258L248.914 335.819L204.802 388.753C203.988 389.729 202.924 390.465 201.724 390.883L181.498 397.918C180.926 398.117 180.427 398.485 180.068 398.972L136.253 458.469C135.318 459.738 135.525 461.514 136.725 462.534L163.172 485.014L160.582 488.062L134.135 465.582C131.333 463.201 130.851 459.058 133.032 456.098L176.847 396.6C177.686 395.462 178.849 394.604 180.184 394.14L200.41 387.105C200.924 386.926 201.38 386.61 201.729 386.192Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M-15 516.5L152.5 264.896L159.256 269.18L-8.24355 520.784L-15 516.5Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M148.25 351.134L118.164 328.128L120.594 324.95L150.679 347.957C153.719 350.281 154.329 354.615 152.049 357.688L146.455 365.227C145.47 366.555 145.745 368.43 147.07 369.419L180.576 394.436L178.182 397.641L144.677 372.624C141.586 370.316 140.944 365.942 143.243 362.844L148.837 355.305C149.814 353.988 149.552 352.13 148.25 351.134Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M62.5664 578.397L278.161 362.391L284.593 368.686L68.9984 584.692L62.5664 578.397Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M254.098 472.003L326.598 411.503L329.16 414.574L256.66 475.074L254.098 472.003Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M284.416 513.241L216.836 431.813L219.919 429.265L287.499 510.693L284.416 513.241Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M90.1594 315.103L-15.5672 228.069L-13.0016 225L92.7251 312.034C98.2499 316.654 97.9198 325.247 92.0569 329.429L-19.5016 404.5L-21.8243 401.243L89.7342 326.172C93.4651 323.511 93.6752 318.043 90.1594 315.103Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M208 -12.5L273.813 57.1628C274.196 57.5539 274.665 57.8499 275.183 58.027L627.059 179.784L623.5 190.192L271.623 68.4351C269.478 67.7016 267.535 66.4754 265.95 64.8551L200.137 -4.80776L208 -12.5Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M252.637 81.2549L266.137 58.2549L275.623 63.8231L262.123 86.8231L252.637 81.2549Z"
				fill="white"
			/>
			<path
				d="M422.379 98.5386L621.874 164.326C623.168 164.753 624.501 163.789 624.501 162.427V159.634C624.501 158.773 623.95 158.009 623.133 157.737L421.814 90.6833C420.752 90.3293 420.187 89.1717 420.562 88.1162L433.214 52.5038C433.322 52.1982 433.503 51.9237 433.742 51.7037L490.995 -1.14833C491.988 -2.06544 491.804 -3.68358 490.631 -4.35439L487.308 -6.2528C486.534 -6.69523 485.56 -6.57162 484.921 -5.94976L427.733 49.6942C427.5 49.9204 427.326 50.1997 427.226 50.5081L413.449 92.7895C413.127 93.7783 413.616 94.8489 414.574 95.2524L422.379 98.5386Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M522.045 349.818C514.515 349.409 507.1 351.805 501.232 356.542L320.563 502.394L213.499 584.192L204.18 572.438L311.218 490.661L491.81 344.87C500.564 337.804 511.625 334.23 522.858 334.84L618.784 340.049L617.971 355.027L522.045 349.818Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M629.502 370.192L523.625 407.296C520.364 408.504 517.334 410.261 514.666 412.491L304.002 586.192L294.383 574.682L505.047 400.981C509.028 397.655 513.548 395.033 518.412 393.231L624.289 356.127L629.502 370.192Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M473.379 376.538V440.538H458.379V376.538H473.379Z"
				fill="white"
			/>
			<path
				class="fill-light"
				d="M226.667 336.164L162.628 286.45C159.35 283.905 154.591 284.754 152.395 288.275L133.723 318.222C131.67 321.514 132.687 325.848 135.989 327.884L160.992 343.297C164.101 345.214 165.214 349.2 163.547 352.45L159.109 361.104C157.591 364.065 158.367 367.686 160.965 369.765L178.664 383.923C180.442 385.346 182.801 385.815 184.988 385.18L195.014 382.269C196.31 381.893 197.47 381.149 198.354 380.129L227.666 346.275C230.293 343.241 229.837 338.625 226.667 336.164Z"
				fill="#FCF3F6"
			/>
		</g>
	</svg>
</template>

<style lang="scss" scoped>
.fill-dark {
	fill: $color-map-dark;
}

.fill-light {
	fill: $color-map-light;
}
</style>
