import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  ref: "list",
  class: "autocomplete__list",
  role: "group",
  "aria-label": "Ergebnisse",
  tabindex: "-1"
}
const _hoisted_2 = { class: "autocomplete__load-box" }
const _hoisted_3 = {
  class: "autocomplete__item-header",
  "aria-hidden": "true"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "autocomplete__search-history-destination" }
const _hoisted_6 = { class: "autocomplete__search-history-departure" }
const _hoisted_7 = { class: "autocomplete__search-history-person" }
const _hoisted_8 = { class: "autocomplete__search-history-duration" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "autocomplete__item-sublabel" }

import topPlacesData from '@json/topPlaces.json';
import axios, { type AxiosError, type Canceler, type AxiosResponse } from 'axios';
import { isOfferlistPage, pluralize, getTravelDuration } from '@utils/utils';
import { formatDateInterval } from '@utils/dateUtils';
import Loading from '@lmt-rpb/Loading/Loading.vue';
import type { SuggestionDataType, FullSuggest, SearchFormDataType } from '@interfaces/search-form';
import { EventBus } from '@global-js/event-bus';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import * as searchHistoryService from '@/components/common/services/localStorage/searchHistoryService';
import airportData from '@/js/data/airports';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';

interface Props {
	url: string;
	label: string;
	filter?: unknown;
	minLength?: number;
	icon: string;
	modelValue: SuggestionDataType | null;
	errorDuration?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Autocomplete',
  props: {
    url: {},
    label: {},
    filter: { default: (data) => data },
    minLength: { default: 2 },
    icon: {},
    modelValue: {},
    errorDuration: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const store = useStore();

const props = __props;

const term = ref<string>('');

const dropdown = ref<InstanceType<typeof DropdownFilterType> | null>(null);

const searchTerm = ref<string>(props.modelValue?.label ?? '');

const { isDesktop } = storeToRefs(useBreakpointStore());

const items = ref<FullSuggest>({});

const loading = ref(false);

const error = ref<AxiosError | null>(null);

const abort = ref<Canceler>();

const activeIndex = ref(0);

const focus = ref(false);

const itemList = ref<HTMLElement>();

const defaultAutosuggestsLoaded = ref(false);

const initialLoad = ref(true);

const emit = __emit;

const minCharCount = computed((): boolean => !!term.value && term.value.length >= props.minLength);

const pageType = computed((): string => store.state.config.pageType);

const itemsArray = computed((): SuggestionDataType[] =>
	Object.keys(items.value).reduce((acc: SuggestionDataType[], cat: string) => {
		const category = (items.value as { [key: string]: SuggestionDataType[] })[cat];
		return [...acc, ...category];
	}, [])
);

let requestAvailableHotels = true;

const getSearchHistory = async () => {
	loading.value = true;
	const searchHistory = await searchHistoryService.getThreeReversed(requestAvailableHotels);
	loading.value = false;
	requestAvailableHotels = false;
	return searchHistory;
};

const getDefaultItems = async (): Promise<FullSuggest> => {
	const searchHistory = await getSearchHistory();
	const defaultItems: FullSuggest = {
		searchHistory,
		RegionGroups: topPlacesData.RegionGroups as SuggestionDataType[],
	};
	if (!searchHistory.length) {
		delete defaultItems.searchHistory;
	}
	return defaultItems;
};

const getAirportLabels = (formData: SearchFormDataType): string => {
	let result = 'Eigene Anreise';
	if (!formData.onlyHotel && formData.departure.length) {
		result =
			'Flug ab ' +
			airportData
				.filter((airport) => formData.departure.includes(airport.value))
				.map((a) => a.label)
				.join(', ');
	} else if (!formData.onlyHotel) {
		result = 'Inkl. Flug';
	}
	return result;
};

const onFocus = (): void => {
	focus.value = true;
};
const onFocusOut = (): void => {
	focus.value = false;
};

const flattenedItemsIndex = (actualKey: string, actualIndex: number): number => {
	let destinationCount = 0;

	for (const [key, destinationArray] of Object.entries(items.value)) {
		if (key === actualKey) {
			destinationCount += actualIndex;
			break;
		}
		destinationCount += destinationArray ? destinationArray.length : 0;
	}

	return destinationCount;
};

const buildUrlWithId = (item: SuggestionDataType): string => {
	const type = item.Type || item.type;
	const id = item.ID || item.id;

	if (!id || !type) {
		return '';
	}

	let url = `/hotel/${id}/`;

	if (type === 'region_group') {
		url = `/region/g/${id}/`;
	} else if (type === 'region') {
		url = `/hotels/r/${id}/`;
	} else if (type === 'city') {
		url = `/hotels/o/${id}/`;
	}
	return url;
};

const itemKeysToLowerCase = (item: SuggestionDataType): SuggestionDataType => {
	const lowerCaseItemKeys: SuggestionDataType = {};

	for (const [key, value] of Object.entries(item)) {
		lowerCaseItemKeys[key.toLowerCase()] = value;
	}

	return lowerCaseItemKeys;
};

const getIdFromUrl = (): number => {
	if (!isOfferlistPage()) {
		return 0;
	}

	let id = null;

	// get id from location path
	const pathArray = window.location.pathname.split('/').filter((fraction) => fraction);
	const onlyNumbers = new RegExp('^[0-9]+$');

	pathArray.forEach((path) => {
		if (path.match(onlyNumbers)) {
			id = +path;
		}
	});

	return id || 0;
};

const getIdFromStore = (): number | false => {
	const regionTypeIds = {
		page_country: 'rgid',
		page_region: 'rid',
		page_city: 'cyid',
	};
	let destinationIdSelector = '',
		destinationId = 0;

	for (const [key, value] of Object.entries(regionTypeIds)) {
		if (document.body.classList.contains(key)) {
			destinationIdSelector = value;
		}
	}

	const regionString = store.state.config[destinationIdSelector];

	if (regionString) {
		destinationId = parseInt(regionString, 10);
		destinationId += destinationIdSelector === 'rgid' ? 40000 : 0; // add 40000 for region groups
	}

	return destinationId || false;
};

const updateBySearchHistoryEntry = (searchParams: SearchFormDataType) => {
	store.commit('searchMask/updateFormData', {
		destination: searchParams.destination,
		departure: searchParams.departure,
		travelDuration: searchParams.travelDuration,
		travelers: searchParams.travelers,
		offerDuration: searchParams.offerDuration,
		onlyHotel: searchParams.onlyHotel,
	});
};

const model = computed({
	get: () => store.state.searchMask.destination,
	set: (newValue) => emit('update:modelValue', newValue),
});

const commitTerm = (): void => {
	store.commit('searchMask/updateFormData', {
		searchTerm: searchTerm.value,
	});
};

const isSearchFormDataType = (item: SuggestionDataType | SearchFormDataType): item is SearchFormDataType =>
	(item as SearchFormDataType).destination !== undefined;

const selectItem = (item: SuggestionDataType | SearchFormDataType, i?: number, key?: string): void => {
	let label;
	if (isSearchFormDataType(item)) {
		updateBySearchHistoryEntry(item);
		label = item.destination?.label ?? '';
	} else {
		label = item.label ?? item.Label ?? '';

		item.url = buildUrlWithId(item);
		model.value = itemKeysToLowerCase(item);
	}
	let index = i;
	if (key) {
		index = flattenedItemsIndex(key, i);
	}
	if (label) {
		searchTerm.value = label;
		term.value = label;
		search();
	}
	if (index !== undefined && index >= 0) {
		activeIndex.value = index;
		selectItemBy(index);
	}
	dropdown.value?.close();

	if (isOfferlistPage() && isDesktop.value) {
		EventBus.$emit('search:submit');
	}
	if (!isDesktop.value) {
		commitTerm();
	}
};

const clearTerm = (): void => {
	term.value = model.value?.label ?? '';
	searchTerm.value = model.value?.label ?? '';
};

const selectItemBy = (index: number): void => {
	if (Object.keys(items.value).length) {
		selectItem(itemsArray.value[index] || itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

const selectFirst = (): void => {
	selectItemBy(activeIndex.value);
};

const selectItemByLabel = (label: string | undefined): void => {
	if (!label) {
		return;
	}

	if (Object.keys(items.value).length) {
		const active = itemsArray.value.find((item) => item.Label === label);
		selectItem(active || itemsArray.value[0]);
	} else {
		clearTerm();
	}
};

const selectItemById = (id: number | false): void => {
	if (!id) {
		return;
	}

	if (Object.keys(items.value).length) {
		const currentDestination: SuggestionDataType | undefined = itemsArray.value.find((item) => item.ID === +id);
		if (currentDestination) {
			currentDestination.url = buildUrlWithId(currentDestination);
		}
	} else {
		clearTerm();
	}
};

const getCategoryName = (category: string): string =>
	(
		({
			Countries: 'Land',
			Hotels: 'Hotel',
			Cities: 'Stadt',
			Regions: 'Region',
			RegionGroups: 'Destinationen',
			searchHistory: 'Zuletzt gesucht',
		}) as { [key: string]: string }
	)[category];

const clearInput = async (): Promise<void> => {
	term.value = '';
	searchTerm.value = '';
	if (model.value) {
		model.value = null;
	}
	items.value = await getDefaultItems();
};
const onAbort = (): void => {
	term.value = model.value?.label ?? term.value;
	searchTerm.value = model.value?.label ?? searchTerm.value;
};
const search = (): void => {
	loading.value = true;
	error.value = null;

	const url = `${props.url}?term=${encodeURIComponent(term.value)}`;

	// if previous search still in progress abort it
	if (abort.value) {
		abort.value();
	}

	axios
		.get(url, {
			cancelToken: new axios.CancelToken((abortCanceler: Canceler) => {
				abort.value = abortCanceler;
			}),
		})
		.then(({ data }: AxiosResponse) => data)
		.then((data: FullSuggest) => {
			const filteredItems: FullSuggest = {};

			for (const [key, value] of Object.entries(data)) {
				if (value && value.length) {
					filteredItems[key] = value;
				}
			}
			items.value = filteredItems;
		})
		.catch((err: AxiosError) => {
			// ignore abort error
			if (err instanceof axios.Cancel) {
				return;
			}

			error.value = err;
		})
		.finally(() => {
			loading.value = false;
			if (initialLoad.value) {
				initialLoad.value = false;
				// Setting the correct item as target destination on initial load
				// to set the URL if user doesn't change anything (on region and city pages)
				if (['regionPage'].indexOf(pageType.value) !== -1 && !document.body.classList.contains('page_country')) {
					selectItemById(getIdFromStore());
				}

				// Getting wrong rgid from typo3 data on some countries, e.g. Spain
				if (document?.body.classList.contains('page_country')) {
					selectItemByLabel(term.value);
				}

				// Get the correct destination (not just the label)
				// for list pages on mobile to prevent unnecessary redirects
				if (['regionList', 'hotelList', 'hotelPage'].indexOf(pageType.value) !== -1) {
					selectItemById(getIdFromUrl());
				}
				if (model.value?.id) {
					store.commit('searchMask/updateFormData', {
						destination: model.value,
					});
					store.dispatch('updateProxies', { initialDestination: model.value });
					EventBus.$emit('Autocomplete:updatedActiveItem');
				}
			}
		});
};

const onTermChange = (): void => {
	error.value = null;
	if (!minCharCount.value) {
		getDefaultItems().then((defaultItems) => {
			items.value = defaultItems;
		});
	} else {
		search();
	}
};
// eslint-disable-next-line
let timeoutID: any = null;
const onInput = (value: string): void => {
	if (value === searchTerm.value) {
		return;
	}
	searchTerm.value = value;
	term.value = value;
	if (timeoutID) {
		clearTimeout(timeoutID);
	}
	timeoutID = setTimeout(() => {
		commitTerm();
		onTermChange();
		timeoutID = null;
	}, 400);
};

const isRegionPage = computed(() => pageType.value === 'regionPage');
const isWhitelistPage = computed(() => pageType.value === 'hotelPage' || isRegionPage.value);

watch(
	() => model.value,
	(newValue) => {
		if (newValue?.label) {
			term.value = newValue.label;
			searchTerm.value = newValue.label;
		}
	},
	{ immediate: true }
);
watch(
	() => items.value,
	() => {
		// we need this for "Urlaubsziele" like Spanien
		if (isRegionPage.value) {
			selectItemByLabel(model.value?.label);
			if (model.value?.id) {
				store.commit('searchMask/updateFormData', {
					destination: model.value,
				});
				store.dispatch('updateProxies', { initialDestination: model.value });
				EventBus.$emit('Autocomplete:updatedActiveItem');
			}
		}
	},
	{ once: true }
);
onMounted((): void => {
	if (isWhitelistPage.value) {
		if (!defaultAutosuggestsLoaded.value) {
			search();
			defaultAutosuggestsLoaded.value = true;
		}
		EventBus.$on('loadDefaultAutosuggests', search);
	}
	onTermChange();
});

onBeforeUnmount((): void => {
	if (isWhitelistPage.value) {
		EventBus.$off('loadDefaultAutosuggests', search);
	}
});

__expose({
	selectFirst,
	clearInput,
	dropdown,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "autocomplete",
    onFocusCapture: onFocus,
    onFocusoutCapture: onFocusOut
  }, [
    _createVNode(DropdownFilterType, {
      ref_key: "dropdown",
      ref: dropdown,
      "selected-value": model.value?.label,
      tabindex: "-1",
      class: "autocomplete__dropdown",
      title: _ctx.label,
      searchable: true,
      placeholder: "Beliebig",
      icon: _ctx.icon,
      "allow-clear": true,
      "apply-button-text": "Übernehmen",
      "search-term": searchTerm.value,
      "with-menu-header": false,
      "emit-on-ok": true,
      "is-focused": focus.value,
      "show-toggle-icon": false,
      "onDropdownFilterType:clear": clearInput,
      "onDropdownFilterType:abort": onAbort,
      "onDropdownFilterType:KeyEnter": selectFirst,
      "onDropdownFilterType:focusedOut": onFocus,
      "onDropdownFilterType:focusedIn": onFocusOut,
      "onUpdate:searchTerm": onInput
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (loading.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createCommentVNode(" Loading "),
                _renderSlot(_ctx.$slots, "loading", {}, () => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(Loading, {
                      size: "small",
                      class: "autocomplete__loader"
                    })
                  ])
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : (error.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createCommentVNode(" Error "),
                  _renderSlot(_ctx.$slots, "error", {
                    error: error.value,
                    term: term.value
                  }, () => [
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "autocomplete__error" }, [
                      _createElementVNode("p", null, "Der Server ist im Augenblick nicht erreichbar."),
                      _createTextVNode(" Bitte versuchen Sie es in Kürze erneut. ")
                    ], -1 /* HOISTED */))
                  ])
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (minCharCount.value && !Object.keys(items.value).length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createCommentVNode(" No result "),
                        _renderSlot(_ctx.$slots, "no-result", {}, () => [
                          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "autocomplete__helper" }, "Keine Ergebnisse gefunden.", -1 /* HOISTED */))
                        ])
                      ], 64 /* STABLE_FRAGMENT */))
                    : _renderSlot(_ctx.$slots, "result", { key: 1 }, () => [
                        _createCommentVNode(" Result list "),
                        _createElementVNode("ul", {
                          ref_key: "itemList",
                          ref: itemList,
                          class: "autocomplete__item-list"
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (category, key) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                              _createCommentVNode(" Category name "),
                              _createElementVNode("li", _hoisted_3, _toDisplayString(minCharCount.value || key === 'searchHistory'
												? getCategoryName(`${key}`)
												: 'Beliebteste Destinationen'), 1 /* TEXT */),
                              _createCommentVNode(" Result item "),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category, (destination, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: `${key}-${index}`
                                }, [
                                  (isSearchFormDataType(destination))
                                    ? (_openBlock(), _createElementBlock("li", {
                                        ref_for: true,
                                        ref: "item",
                                        key: `${key}-${index}`,
                                        role: "button",
                                        class: _normalizeClass(["autocomplete__item", { 'is-active': flattenedItemsIndex(key, index) === 0 }]),
                                        onClick: ($event: any) => (selectItem(destination, index))
                                      }, [
                                        _createElementVNode("div", _hoisted_5, _toDisplayString(destination.destination?.label), 1 /* TEXT */),
                                        _createElementVNode("div", _hoisted_6, _toDisplayString(getAirportLabels(destination)), 1 /* TEXT */),
                                        _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(pluralize)(destination.travelers.adult, 'Erwachsener', 'Erwachsene')) + " " + _toDisplayString(destination.travelers.children.length
														? ', ' + _unref(pluralize)(destination.travelers.children.length, 'Kind', 'Kinder')
														: ''), 1 /* TEXT */),
                                        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(formatDateInterval)(destination.offerDuration.from, destination.offerDuration.to)) + ", " + _toDisplayString(_unref(getTravelDuration)(destination.travelDuration)), 1 /* TEXT */)
                                      ], 10 /* CLASS, PROPS */, _hoisted_4))
                                    : (_openBlock(), _createElementBlock("li", {
                                        key: 1,
                                        ref_for: true,
                                        ref: "item",
                                        role: "button",
                                        class: _normalizeClass(["autocomplete__item", { 'is-active': flattenedItemsIndex(key, index) === 0 }]),
                                        onClick: ($event: any) => (selectItem(destination, index, key))
                                      }, [
                                        _createTextVNode(_toDisplayString(destination.Label) + " ", 1 /* TEXT */),
                                        _createElementVNode("span", _hoisted_10, _toDisplayString(destination.SubLabel), 1 /* TEXT */)
                                      ], 10 /* CLASS, PROPS */, _hoisted_9))
                                ], 64 /* STABLE_FRAGMENT */))
                              }), 128 /* KEYED_FRAGMENT */))
                            ], 64 /* STABLE_FRAGMENT */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ], 512 /* NEED_PATCH */)
                      ])
                ], 64 /* STABLE_FRAGMENT */))
        ], 512 /* NEED_PATCH */)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["selected-value", "title", "icon", "search-term", "is-focused"])
  ], 32 /* NEED_HYDRATION */))
}
}

})